const initialState = {
  nama: "",
  username: "",
  role: "",
};

const authReducer = (state = initialState, action) => {
  let { type, data } = action;

  switch (type) {
    case "SET_IDENTITY":
      return {
        ...state,
        nama: data.nama,
        username: data.username,
        role: data.role,
      };
    default:
      return state;
  }
};

export default authReducer;
