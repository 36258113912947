import React from "react";
// react component used to create charts
import ChartistGraph from "react-chartist";

import { VictoryPie, VictoryTooltip, VictoryLabel } from "victory";
// react components used to create a SVG / Vector map
import { VectorMap } from "react-jvectormap";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  InputGroup,
  Navbar,
  Nav,
  OverlayTrigger,
  Table,
  Tooltip,
  Container,
  Row,
  Col,
} from "react-bootstrap";

import { useDispatch, useSelector } from "react-redux";
import CurrencyInput from "react-currency-input-field";
import {
  totalPurchaseOrderDashboard,
  dataPurchaseOrderDashboard,
  totalPartnerDashboard,
  totalNominalDashboard,
  totalProjectDashboard,
  totalStatusDashboard,
} from "stores";
import DataTable from "react-data-table-component";
import { useHistory } from "react-router-dom";

function DashboardUtama() {
  const dispatch = useDispatch();
  const history = useHistory();

  const purchaseOrder = useSelector((state) => state.dashboardUtamaReducer);

  const [TotalPO, setTotalPO] = React.useState("");
  const [totalPartner, setTotalPartner] = React.useState("");
  const [totalNominal, setTotalNominal] = React.useState(0);
  const [totalProject, setTotalProject] = React.useState("");
  const [totalStatus, setTotalStatus] = React.useState("");
  const [listPurchaseOrder, setListPurchaseOrder] = React.useState([]);
  const itemsPerPage = 5;

  React.useEffect(() => {
    dataPurchaseOrderDashboard(dispatch);
  }, []);

  React.useEffect(() => {
    totalPurchaseOrderDashboard(dispatch).then((response) => {
      const dataTotalPO = response.data;
      setTotalPO(dataTotalPO);
    });
  }, []);

  React.useEffect(() => {
    let tmp =
      purchaseOrder.dataPurchaseOrder &&
      purchaseOrder.dataPurchaseOrder.map((val, index) => {
        // console.log(val);
        let datenow = new Date().getTime();
        let datedateline = new Date(val.dateline).getTime();
        let selisih = Math.round((datedateline - datenow) / (1000 * 3600 * 24));
        return {
          ...val,
          countdown: selisih,
          actions: <div className="actions-right">{}</div>,
        };
      });
    setListPurchaseOrder(tmp);
  }, [purchaseOrder.dataPurchaseOrder]);

  React.useEffect(() => {
    totalPartnerDashboard(dispatch).then((response) => {
      const dataTotalPartner = response.data;
      setTotalPartner(dataTotalPartner);
    });
  }, []);

  React.useEffect(() => {
    totalNominalDashboard(dispatch).then((response) => {
      const dataTotalNominal = response.data.totalNominalDashboard;
      setTotalNominal(dataTotalNominal);
    });
  }, []);

  React.useEffect(() => {
    totalProjectDashboard(dispatch).then((response) => {
      const dataTotalProject = response.data;
      setTotalProject(dataTotalProject);
    });
  }, []);

  React.useEffect(() => {
    totalStatusDashboard(dispatch).then((response) => {
      const dataTotalStatus = response.data.data;
      // console.log(dataTotalStatus, "AAAAAAAAAAA");
      setTotalStatus(dataTotalStatus);
    });
  }, []);

  const customStyles = {
    rows: {
      style: {
        height: 70, // override the row height
      },
    },
    headRow: {
      style: {
        minHeight: 35,
        backgroundColor: "#F8F8F8",
        //fontFamily: "Poppins",
        justifyContent: "center",
      },
    },
    headCells: {
      style: {
        minHeight: 50,
        fontWeight: "bold",
        fontSize: 14,
      },
    },
    cells: {
      style: {
        minHeight: 50,
      },
    },
  };

  const colPurchaseOrder = [
    {
      name: "Sisa Hari",
      minWidth: "160px",
      sortable: true,
      center: true,
      selector: (row) => (!row.countdown ? "-" : `${row.countdown} Hari`),
      conditionalCellStyles: [
        {
          when: (row) => row.countdown <= 30,
          style: {
            color: "rgba(255, 0, 0, 100)",
            fontWeight: "bold",
            "&:hover": {
              cursor: "pointer",
            },
          },
        },
        {
          when: (row) => row.countdown < 61 && row.countdown > 30,
          style: {
            color: "rgba(255, 179, 0, 100)",
            fontWeight: "bold",
            "&:hover": {
              cursor: "pointer",
            },
          },
        },
      ],
    },
    {
      name: "Nama Project",
      minWidth: "180px",
      center: true,
      selector: (row) => row.namaProject,
    },
    {
      name: "Kode Project",
      minWidth: "180px",
      center: true,
      selector: (row) => row.kodeProject,
    },

    {
      name: "Nomer PO",
      minWidth: "150px",
      center: true,
      selector: (row) => row.noPO,
    },
    {
      name: "Nama Partner",
      minWidth: "320px",
      center: true,
      selector: (row) => row.namaPartner,
    },
  ];

  const handleHalamanPO = () => {
    history.push("/admin/purchase-order");
  };

  const handleHalamanPartner = () => {
    history.push("/admin/partner");
  };

  const handleHalamanNominal = () => {
    history.push("/admin/total-purchase-order");
  };

  const handleHalamanProject = () => {
    history.push("/admin/project");
  };

  const CustomLabel = (props) => {
    return (
      <>
        <VictoryLabel {...props} />
        <VictoryTooltip
          {...props}
          x={200}
          y={250}
          orientation="top"
          pointerLength={0}
          cornerRadius={50}
          flyoutWidth={100}
          flyoutHeight={100}
          flyoutStyle={{ fill: "#54AB6A" }}
        />
      </>
    );
  };

  CustomLabel.defaultEvents = VictoryTooltip.defaultEvents;
  return (
    <>
      <Container fluid>
        <Row>
          <Col lg="3" sm="6">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  {/* <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-chart text-warning"></i>
                    </div>
                  </Col> */}
                  <Col sm="12">
                    <div className="numbers">
                      <p className="card-category">Total Purchase Order</p>
                      <Card.Title as="h4">
                        {TotalPO.totalPurchaseOrderDashboard} Purchase Order
                      </Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <hr></hr>
                <div style={{ textAlign: "end" }}>
                  <Button
                    className="mr-2"
                    variant="info"
                    style={{
                      marginTop: 5,
                      padding: "6px 15px",
                      fontSize: "14px",
                    }}
                    onClick={() => {
                      handleHalamanPO();
                    }}
                  >
                    Detail
                  </Button>
                </div>
              </Card.Footer>
            </Card>
          </Col>
          <Col lg="3" sm="6">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col sm="12">
                    <div className="numbers">
                      <p className="card-category">Total Partner</p>
                      <Card.Title as="h4">
                        {totalPartner.totalPartnerDashboard} Partner
                      </Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <hr></hr>
                <div style={{ textAlign: "end" }}>
                  <Button
                    className="mr-2"
                    variant="info"
                    style={{
                      marginTop: 5,
                      padding: "6px 15px",
                      fontSize: "14px",
                    }}
                    onClick={() => {
                      handleHalamanPartner();
                    }}
                  >
                    Detail
                  </Button>
                </div>
              </Card.Footer>
            </Card>
          </Col>
          <Col lg="3" sm="6">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col sm="12">
                    <div className="numbers">
                      <p className="card-category">Total Nominal</p>
                      <Card.Title as="h4">
                        {`Rp ${totalNominal.toLocaleString("id-ID")}`}
                      </Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <hr></hr>
                <div style={{ textAlign: "end" }}>
                  <Button
                    className="mr-2"
                    variant="info"
                    style={{
                      marginTop: 5,
                      padding: "6px 15px",
                      fontSize: "14px",
                    }}
                    onClick={() => {
                      handleHalamanNominal();
                    }}
                  >
                    Detail
                  </Button>
                </div>
              </Card.Footer>
            </Card>
          </Col>
          <Col lg="3" sm="6">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  {/* <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-favourite-28 text-primary"></i>
                    </div>
                  </Col> */}
                  <Col sm="12">
                    <div className="numbers">
                      <p className="card-category">Total Project</p>
                      <Card.Title as="h4">
                        {totalProject.totalProjectDashboard} Project
                      </Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <hr></hr>
                <div style={{ textAlign: "end" }}>
                  <Button
                    className="mr-2"
                    variant="info"
                    style={{
                      marginTop: 5,
                      padding: "6px 15px",
                      fontSize: "14px",
                    }}
                    onClick={() => {
                      handleHalamanProject();
                    }}
                  >
                    Detail
                  </Button>
                </div>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            {/* <Card>
              <Card.Header>
                <Card.Title as="h4">Global Sales by Top Locations</Card.Title>
                <p className="card-category">All products that were shipped</p>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col md="6">
                    <Table responsive>
                      <tbody>
                        <tr>
                          <td>
                            <div className="flag">
                              <img
                                alt="..."
                                src={require("assets/img/flags/US.png").default}
                              ></img>
                            </div>
                          </td>
                          <td>USA</td>
                          <td className="text-right">2.920</td>
                          <td className="text-right">53.23%</td>
                        </tr>
                        <tr>
                          <td>
                            <div className="flag">
                              <img
                                alt="..."
                                src={require("assets/img/flags/DE.png").default}
                              ></img>
                            </div>
                          </td>
                          <td>Germany</td>
                          <td className="text-right">1.300</td>
                          <td className="text-right">20.43%</td>
                        </tr>
                        <tr>
                          <td>
                            <div className="flag">
                              <img
                                alt="..."
                                src={require("assets/img/flags/AU.png").default}
                              ></img>
                            </div>
                          </td>
                          <td>Australia</td>
                          <td className="text-right">760</td>
                          <td className="text-right">10.35%</td>
                        </tr>
                        <tr>
                          <td>
                            <div className="flag">
                              <img
                                alt="..."
                                src={require("assets/img/flags/GB.png").default}
                              ></img>
                            </div>
                          </td>
                          <td>United Kingdom</td>
                          <td className="text-right">690</td>
                          <td className="text-right">7.87%</td>
                        </tr>
                        <tr>
                          <td>
                            <div className="flag">
                              <img
                                alt="..."
                                src={require("assets/img/flags/RO.png").default}
                              ></img>
                            </div>
                          </td>
                          <td>Romania</td>
                          <td className="text-right">600</td>
                          <td className="text-right">5.94%</td>
                        </tr>
                        <tr>
                          <td>
                            <div className="flag">
                              <img
                                alt="..."
                                src={require("assets/img/flags/BR.png").default}
                              ></img>
                            </div>
                          </td>
                          <td>Brasil</td>
                          <td className="text-right">550</td>
                          <td className="text-right">4.34%</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                  <Col className="ml-auto mr-auto" md="6">
                    <VectorMap
                      map={"world_mill"}
                      backgroundColor="transparent"
                      zoomOnScroll={false}
                      containerStyle={{
                        width: "100%",
                        height: "300px",
                      }}
                      containerClassName="map"
                      regionStyle={{
                        initial: {
                          fill: "#e4e4e4",
                          "fill-opacity": 0.9,
                          stroke: "none",
                          "stroke-width": 0,
                          "stroke-opacity": 0,
                        },
                      }}
                      series={{
                        regions: [
                          {
                            values: {
                              AU: 760,
                              BR: 550,
                              CA: 120,
                              DE: 1300,
                              FR: 540,
                              GB: 690,
                              GE: 200,
                              IN: 200,
                              RO: 600,
                              RU: 300,
                              US: 2920,
                            },
                            scale: ["#AAAAAA", "#444444"],
                            normalizeFunction: "polynomial",
                          },
                        ],
                      }}
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card> */}
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <Card>
              <Card.Header>
                <Card.Title as="h4">Grafik Status Purchase Order</Card.Title>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col md="8">
                    {/* <ChartistGraph
                      className="ct-perfect-fourth"
                      data={totalStatus}
                      type="Pie"
                      options={{
                        chartPadding: 20,
                        labelOffset: 70,
                        showLabel: true,
                      }}
                    /> */}
                    <div style={{ padding: 5 }}>
                      <VictoryPie
                        style={{
                          labels: { fill: "white", fontWeight: "bold" },
                        }}
                        innerRadius={80}
                        labelRadius={90}
                        labels={({ datum }) => `${datum.y}%`}
                        labelComponent={<CustomLabel />}
                        data={totalStatus}
                        colorScale={[
                          "#23CCEF",
                          "#FB404B",
                          "#FFA534",
                          "#9368E9",
                          "#87CB16",
                        ]}
                      />
                      {/* <VictoryPie /> */}
                    </div>
                  </Col>
                  <Col>
                    <p style={{ fontSize: 14 }}>
                      <i className="fas fa-circle mr-1 text-info"></i>
                      Dibagikan
                    </p>
                    <p style={{ fontSize: 14 }}>
                      <i className="fas fa-circle mr-1 text-danger"></i>
                      Ditolak
                    </p>
                    <p style={{ fontSize: 14 }}>
                      <i className="fas fa-circle mr-1 text-warning"></i>
                      Dikirim
                    </p>
                    <p style={{ fontSize: 14 }}>
                      <i
                        className="fas fa-circle mr-1"
                        style={{ color: "#9368E9" }}
                      ></i>
                      Diproses
                    </p>
                    <p style={{ fontSize: 14 }}>
                      <i className="fas fa-circle mr-1 text-success"></i>
                      Diterima
                    </p>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6">
            <Card>
              <Card.Header>
                <Card.Title as="h4">Sisa Hari Purchase Order</Card.Title>
              </Card.Header>
              <Card.Body>
                <DataTable
                  columns={colPurchaseOrder}
                  data={listPurchaseOrder}
                  customStyles={customStyles}
                  pagination
                  paginationRowsPerPageOptions={[5, 10, 15, 20, 25]}
                  paginationPerPage={itemsPerPage}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
        {/* <Row>
          <Col md="6">
            <Card>
              <Card.Header>
                <Card.Title as="h4">2017 Sales</Card.Title>
                <p className="card-category">All products including Taxes</p>
              </Card.Header>
              <Card.Body>
                <ChartistGraph
                  data={{
                    labels: [
                      "Jan",
                      "Feb",
                      "Mar",
                      "Apr",
                      "Mai",
                      "Jun",
                      "Jul",
                      "Aug",
                      "Sep",
                      "Oct",
                      "Nov",
                      "Dec",
                    ],
                    series: [
                      [
                        542, 443, 320, 780, 553, 453, 326, 434, 568, 610, 756,
                        895,
                      ],
                      [
                        412, 243, 280, 580, 453, 353, 300, 364, 368, 410, 636,
                        695,
                      ],
                    ],
                  }}
                  type="Bar"
                  options={{
                    seriesBarDistance: 10,
                    axisX: {
                      showGrid: false,
                    },
                    height: "245px",
                  }}
                  responsiveOptions={[
                    [
                      "screen and (max-width: 640px)",
                      {
                        seriesBarDistance: 5,
                        axisX: {
                          labelInterpolationFnc: function (value) {
                            return value[0];
                          },
                        },
                      },
                    ],
                  ]}
                />
              </Card.Body>
              <Card.Footer>
                <div className="legend">
                  <i className="fas fa-circle mr-1 text-info"></i>
                  Tesla Model S{" "}
                  <i className="fas fa-circle mr-1 text-danger"></i>
                  BMW 5 Series
                </div>
                <hr></hr>
                <div className="stats">
                  <i className="fas fa-check"></i>
                  Data information certified
                </div>
              </Card.Footer>
            </Card>
          </Col>
          <Col md="6">
            <Card className="card-tasks">
              <Card.Header>
                <Card.Title as="h4">Tasks</Card.Title>
                <p className="card-category">Backend development</p>
              </Card.Header>
              <Card.Body>
                <div className="table-full-width">
                  <Table>
                    <tbody>
                      <tr>
                        <td>
                          <Form.Check className="mb-1 pl-0">
                            <Form.Check.Label>
                              <Form.Check.Input
                                defaultValue=""
                                type="checkbox"
                              ></Form.Check.Input>
                              <span className="form-check-sign"></span>
                            </Form.Check.Label>
                          </Form.Check>
                        </td>
                        <td>
                          Sign contract for "What are conference organizers
                          afraid of?"
                        </td>
                        <td className="td-actions text-right">
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="tooltip-688296980">
                                Edit Task..
                              </Tooltip>
                            }
                            placement="top"
                          >
                            <Button
                              className="btn-simple btn-link"
                              type="button"
                              variant="info"
                            >
                              <i className="fas fa-edit"></i>
                            </Button>
                          </OverlayTrigger>
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="tooltip-202192706">Remove..</Tooltip>
                            }
                            placement="top"
                          >
                            <Button
                              className="btn-simple btn-link"
                              type="button"
                              variant="danger"
                            >
                              <i className="fas fa-times"></i>
                            </Button>
                          </OverlayTrigger>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Form.Check className="mb-1 pl-0">
                            <Form.Check.Label>
                              <Form.Check.Input
                                defaultChecked
                                defaultValue=""
                                type="checkbox"
                              ></Form.Check.Input>
                              <span className="form-check-sign"></span>
                            </Form.Check.Label>
                          </Form.Check>
                        </td>
                        <td>
                          Lines From Great Russian Literature? Or E-mails From
                          My Boss?
                        </td>
                        <td className="td-actions text-right">
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="tooltip-746544352">
                                Edit Task..
                              </Tooltip>
                            }
                            placement="top"
                          >
                            <Button
                              className="btn-simple btn-link"
                              type="button"
                              variant="info"
                            >
                              <i className="fas fa-edit"></i>
                            </Button>
                          </OverlayTrigger>
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="tooltip-743037005">Remove..</Tooltip>
                            }
                            placement="top"
                          >
                            <Button
                              className="btn-simple btn-link"
                              type="button"
                              variant="danger"
                            >
                              <i className="fas fa-times"></i>
                            </Button>
                          </OverlayTrigger>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Form.Check className="mb-1 pl-0">
                            <Form.Check.Label>
                              <Form.Check.Input
                                defaultChecked
                                defaultValue=""
                                type="checkbox"
                              ></Form.Check.Input>
                              <span className="form-check-sign"></span>
                            </Form.Check.Label>
                          </Form.Check>
                        </td>
                        <td>
                          Flooded: One year later, assessing what was lost and
                          what was found when a ravaging rain swept through
                          metro Detroit
                        </td>
                        <td className="td-actions text-right">
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="tooltip-855684210">
                                Edit Task..
                              </Tooltip>
                            }
                            placement="top"
                          >
                            <Button
                              className="btn-simple btn-link"
                              type="button"
                              variant="info"
                            >
                              <i className="fas fa-edit"></i>
                            </Button>
                          </OverlayTrigger>
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="tooltip-242945902">Remove..</Tooltip>
                            }
                            placement="top"
                          >
                            <Button
                              className="btn-simple btn-link"
                              type="button"
                              variant="danger"
                            >
                              <i className="fas fa-times"></i>
                            </Button>
                          </OverlayTrigger>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Form.Check className="mb-1 pl-0">
                            <Form.Check.Label>
                              <Form.Check.Input
                                defaultChecked
                                type="checkbox"
                              ></Form.Check.Input>
                              <span className="form-check-sign"></span>
                            </Form.Check.Label>
                          </Form.Check>
                        </td>
                        <td>
                          Create 4 Invisible User Experiences you Never Knew
                          About
                        </td>
                        <td className="td-actions text-right">
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="tooltip-488557184">
                                Edit Task..
                              </Tooltip>
                            }
                            placement="top"
                          >
                            <Button
                              className="btn-simple btn-link"
                              type="button"
                              variant="info"
                            >
                              <i className="fas fa-edit"></i>
                            </Button>
                          </OverlayTrigger>
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="tooltip-789597281">Remove..</Tooltip>
                            }
                            placement="top"
                          >
                            <Button
                              className="btn-simple btn-link"
                              type="button"
                              variant="danger"
                            >
                              <i className="fas fa-times"></i>
                            </Button>
                          </OverlayTrigger>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Form.Check className="mb-1 pl-0">
                            <Form.Check.Label>
                              <Form.Check.Input
                                defaultValue=""
                                type="checkbox"
                              ></Form.Check.Input>
                              <span className="form-check-sign"></span>
                            </Form.Check.Label>
                          </Form.Check>
                        </td>
                        <td>Read "Following makes Medium better"</td>
                        <td className="td-actions text-right">
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="tooltip-521344137">
                                Edit Task..
                              </Tooltip>
                            }
                            placement="top"
                          >
                            <Button
                              className="btn-simple btn-link"
                              type="button"
                              variant="info"
                            >
                              <i className="fas fa-edit"></i>
                            </Button>
                          </OverlayTrigger>
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="tooltip-934093947">Remove..</Tooltip>
                            }
                            placement="top"
                          >
                            <Button
                              className="btn-simple btn-link"
                              type="button"
                              variant="danger"
                            >
                              <i className="fas fa-times"></i>
                            </Button>
                          </OverlayTrigger>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Form.Check className="mb-1 pl-0">
                            <Form.Check.Label>
                              <Form.Check.Input
                                defaultChecked
                                defaultValue=""
                                type="checkbox"
                              ></Form.Check.Input>
                              <span className="form-check-sign"></span>
                            </Form.Check.Label>
                          </Form.Check>
                        </td>
                        <td>Unfollow 5 enemies from twitter</td>
                        <td className="td-actions text-right">
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="tooltip-97404283">
                                Edit Task..
                              </Tooltip>
                            }
                            placement="top"
                          >
                            <Button
                              className="btn-simple btn-link"
                              type="button"
                              variant="info"
                            >
                              <i className="fas fa-edit"></i>
                            </Button>
                          </OverlayTrigger>
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="tooltip-888894273">Remove..</Tooltip>
                            }
                            placement="top"
                          >
                            <Button
                              className="btn-simple btn-link"
                              type="button"
                              variant="danger"
                            >
                              <i className="fas fa-times"></i>
                            </Button>
                          </OverlayTrigger>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </Card.Body>
              <Card.Footer>
                <hr></hr>
                <div className="stats">
                  <i className="now-ui-icons loader_refresh spin"></i>
                  Updated 3 minutes ago
                </div>
              </Card.Footer>
            </Card>
          </Col>
        </Row> */}
      </Container>
    </>
  );
}

export default DashboardUtama;
