const initialState = {
  totalPurchaseOrderDashboard: [],
  dataPurchaseOrderDashboard: [],
  totalPartnerDashboard: [],
  totalNominalDashboard: [],
  totalProjectDashboard: [],
  totalStatusDashboard: [],
};

const dashboardUtamaReducer = (state = initialState, action) => {
  let { type, data } = action;

  switch (type) {
    case "SET_GETALL_TOTALPURCHASEORDERDASHBOARD":
      return {
        ...state,
        totalPurchaseOrderDashboard: data,
      };
    case "SET_GETALL_DATAPURCHASEORDERDASHBOARD":
      return {
        ...state,
        dataPurchaseOrder: data,
      };
    case "SET_GETALL_TOTALPARTNERDASHBOARD":
      return {
        ...state,
        totalPartnerDashboard: data,
      };
    case "SET_GETALL_TOTALNOMINALDASHBOARD":
      return {
        ...state,
        totalNominalDashboard: data,
      };
    case "SET_GETALL_TOTALPROJECTDASHBOARD":
      return {
        ...state,
        totalProjectDashboard: data,
      };
    case "SET_GETALL_TOTALSTATUSDASHBOARD":
      return {
        ...state,
        totalStatusDashboard: data,
      };
    default:
      return state;
  }
};

export default dashboardUtamaReducer;
