import React from "react";

// react-bootstrap components
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Modal,
  Form,
  Tab,
  Table,
  Image,
  FormGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import Select from "react-select";
// core components
import ReactTable from "components/ReactTable/ReactTable.js";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom/cjs/react-router-dom";
import axios from "axios";
import {
  addPurchaseOrder,
  dataPurchaseOrder,
  dataProject,
  addProject,
  updatePurchaseOrder,
  updateStatusPurchaseOrder,
  downloadDataExcel,
  tambahDokumenAkhir,
  tambahPartner,
  dataPartner,
} from "../../stores";

import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactDatetime from "react-datetime";
import moment from "moment";
import { actions } from "react-table";
import CurrencyInput from "react-currency-input-field";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";

function TambahPurchaseOrder() {
  const dispatch = useDispatch();
  const date = new Date();
  const purchaseOrder = useSelector((state) => state.purchaseOrderReducer);

  const getdataProject = useSelector((state) => state.dataProjectReducer);

  const getdataPartner = useSelector((state) => state.dataPartnerReducer);

  const auth = useSelector((state) => state.authReducer);
  console.log(auth, "AUTHHHHHHHHHHHHHHHHHHHHHH");

  const [nomerpo, setNomerPO] = React.useState("");
  const [namamaterial, setNamaMaterial] = React.useState("");
  const [quantity, setQuantity] = React.useState("");
  const [nominal, setNominal] = React.useState("");
  const [ttdkontrak, setTtdKontrak] = React.useState("");
  const [dateline, setDateline] = React.useState("");
  const [dokumenfile, setDokumenFile] = React.useState("");
  const [dokumenakhir, setDokumenAkhir] = React.useState("");
  const [modalAddPO, setmodalAddPO] = React.useState(false);
  const [modalUpdateStatus, setModalUpdateStatus] = React.useState(false);
  const [modalDokumenAkhir, setModalDokumenAkhir] = React.useState(false);
  const [searchText, setSearchText] = React.useState("");
  const [filterStatus, setFilterStatus] = React.useState("");
  const [detailStatus, setDetailStatus] = React.useState([]);
  const [statusA, setStatusA] = React.useState("12");
  const [statusB, setStatusB] = React.useState("0");
  const [previewPDF, setPreviewPDF] = React.useState(false);
  const [previewPDFAkhir, setPreviewPDFAkhir] = React.useState(false);
  const [idDokumenAkhir, setIdDokumenAkhir] = React.useState("");
  const [idUpdateStatus, setIdUpdateStatus] = React.useState("");
  const [listProjectDropDownBaru, SetListProjectDropDownBaru] =
    React.useState("");
  const [listPartnerDropDownBaru, SetListPartnerDropDownBaru] =
    React.useState("");
  const [idProject, setIdProject] = React.useState({
    value: "",
    label: "Pilih Nama Project",
    isDisabled: true,
  });
  const [idPartner, setIdPartner] = React.useState({
    value: "",
    label: "Pilih Nama Partner",
    isDisabled: true,
  });
  const [listPurchaseOrder, setListPurchaseOrder] = React.useState([]);
  const [liststatus, setListStatus] = React.useState([]);
  // const [dariTgl, setDariTgl] = React.useState(
  //   new Date(date.getFullYear(), date.getMonth(), date.getDate()).setHours(
  //     7,
  //     0,
  //     0,
  //     0
  //   )
  // );
  const [statuspurchaseorder, setStatusPurchaseOrder] = React.useState("");
  const [keterangan, setKeterangan] = React.useState("");
  const [namafile, setNamaFile] = React.useState("");
  const [selisih, setSelisih] = React.useState("");
  const itemsPerPage = 5;
  const userRole = auth.role;

  React.useEffect(() => {
    let tmp =
      purchaseOrder.dataPurchaseOrder &&
      purchaseOrder.dataPurchaseOrder.map((val, index) => {
        let datenow = new Date().getTime();
        let datedateline = new Date(val.dateline).getTime();
        let selisih = Math.round((datedateline - datenow) / (1000 * 3600 * 24));

        let actions;
        if (userRole === "superadmin") {
          // Jika user adalah superadmin, tampilkan semua tombol
          actions = (
            <div className="actions-right">
              {/* Tombol Detail */}
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>Detail</Tooltip>}
              >
                <Button
                  className="mr-2"
                  variant="info"
                  style={{
                    marginTop: 5,
                    padding: "6px 15px",
                    fontSize: "18px",
                  }}
                  onClick={() => {
                    setDetailStatus(val);
                    setStatusA("6");
                    setStatusB("6");
                    setSelisih(selisih);
                  }}
                >
                  <i class="fas fa-info"></i>
                </Button>
              </OverlayTrigger>

              {/* Tombol Update Status */}
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>Update Status</Tooltip>}
              >
                <Button
                  className="mr-2"
                  variant="warning"
                  style={{
                    marginTop: 5,
                    padding: "6px 15px",
                    fontSize: "18px",
                  }}
                  onClick={() => {
                    setIdUpdateStatus(val._id);
                    setModalUpdateStatus(val);
                  }}
                >
                  <i class="fas fa-edit"></i>
                </Button>
              </OverlayTrigger>

              {/* Tombol Tambah Dokumen Akhir */}
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>Tambah Dokumen Akhir</Tooltip>}
              >
                <Button
                  className="mr-2"
                  variant="primary"
                  style={{
                    marginTop: 5,
                    padding: "6px 15px",
                    fontSize: "18px",
                  }}
                  onClick={() => {
                    setIdDokumenAkhir(val._id);
                    setModalDokumenAkhir(val);
                  }}
                >
                  <i class="fas fa-plus"></i>
                </Button>
              </OverlayTrigger>
            </div>
          );
        } else if (userRole === "admin") {
          // Jika user adalah admin, tampilkan hanya tombol Detail
          actions = (
            <div className="actions-right">
              {/* Tombol Detail */}
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>Detail</Tooltip>}
              >
                <Button
                  className="mr-2"
                  variant="info"
                  style={{
                    marginTop: 5,
                    padding: "6px 15px",
                    fontSize: "18px",
                  }}
                  onClick={() => {
                    setDetailStatus(val);
                    setStatusA("6");
                    setStatusB("6");
                    setSelisih(selisih);
                  }}
                >
                  <i class="fas fa-info"></i>
                </Button>
              </OverlayTrigger>
            </div>
          );
        }

        return {
          ...val,
          countdown: selisih,
          actions: actions,
        };
      });

    setListPurchaseOrder(tmp);
    setListStatus(tmp);
  }, [purchaseOrder.dataPurchaseOrder, userRole]);

  // React.useEffect(() => {
  //   let tmp =
  //     purchaseOrder.dataPurchaseOrder &&
  //     purchaseOrder.dataPurchaseOrder.map((val, index) => {
  //       let datenow = new Date().getTime();
  //       let datedateline = new Date(val.dateline).getTime();
  //       let selisih = Math.round((datedateline - datenow) / (1000 * 3600 * 24));
  //       return {
  //         ...val,
  //         countdown: selisih,
  //         actions: (
  //           <div className="actions-right">
  //               <OverlayTrigger
  //                 placement="top"
  //                 overlay={<Tooltip>Detail</Tooltip>}
  //               >
  //                 <Button
  //                   className="mr-2"
  //                   variant="info"
  //                   style={{
  //                     marginTop: 5,
  //                     padding: "6px 15px",
  //                     fontSize: "18px",
  //                   }}
  //                   onClick={() => {
  //                     setDetailStatus(val);
  //                     setStatusA("6");
  //                     setStatusB("6");
  //                     setSelisih(selisih);
  //                   }}
  //                 >
  //                   <i class="fas fa-info"></i>
  //                 </Button>
  //               </OverlayTrigger>

  //             <OverlayTrigger
  //               placement="top"
  //               overlay={<Tooltip>Update Status</Tooltip>}
  //             >
  //               <Button
  //                 className="mr-2"
  //                 variant="warning"
  //                 style={{
  //                   marginTop: 5,
  //                   padding: "6px 15px",
  //                   fontSize: "18px",
  //                 }}
  //                 onClick={() => {
  //                   setIdUpdateStatus(val._id);
  //                   setModalUpdateStatus(val);
  //                 }}
  //               >
  //                 <i class="fas fa-edit"></i>
  //               </Button>
  //             </OverlayTrigger>

  //             <OverlayTrigger
  //               placement="top"
  //               overlay={<Tooltip>Tambah Dokumen Akhir</Tooltip>}
  //             >
  //               <Button
  //                 className="mr-2"
  //                 variant="primary"
  //                 style={{
  //                   marginTop: 5,
  //                   padding: "6px 15px",
  //                   fontSize: "18px",
  //                 }}
  //                 onClick={() => {
  //                   setIdDokumenAkhir(val._id);
  //                   setModalDokumenAkhir(val);
  //                 }}
  //               >
  //                 <i class="fas fa-plus"></i>
  //               </Button>
  //             </OverlayTrigger>
  //           </div>
  //         ),
  //       };
  //     });
  //   setListPurchaseOrder(tmp);
  //   setListStatus(tmp);
  // }, [purchaseOrder.dataPurchaseOrder]);

  React.useEffect(() => {
    let tmp =
      getdataProject.dataProject &&
      getdataProject.dataProject.map((val) => {
        // console.log(val, "????");
        return {
          ...val,
          label: `${val.nomerProject}. ${val.namaProject} : ${val.kodeProject}`,
          value: val.namaProject,
        };
      });
    SetListProjectDropDownBaru(tmp);
  }, [getdataProject.dataProject]);

  React.useEffect(() => {
    let tmp =
      getdataPartner.dataPartner &&
      getdataPartner.dataPartner.map((val) => {
        // console.log(val, "????");
        return {
          ...val,
          label: `${val.nomerPartner}. ${val.namaPartner}`,
          value: val.namaPartner,
        };
      });
    SetListPartnerDropDownBaru(tmp);
  }, [getdataPartner.dataPartner]);

  const customStyles = {
    rows: {
      style: {
        height: 70, // override the row height
      },
    },
    headRow: {
      style: {
        minHeight: 35,
        backgroundColor: "#F8F8F8",
        //fontFamily: "Poppins",
        justifyContent: "center",
      },
    },
    headCells: {
      style: {
        minHeight: 50,
        fontWeight: "bold",
        fontSize: 14,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
    cells: {
      style: {
        minHeight: 50,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
  };

  const colPurchaseOrder = [
    {
      name: "No",
      minWidth: "40px",
      center: true,
      selector: (row) => listPurchaseOrder.indexOf(row) + 1,
    },
    {
      name: "Nama Project",
      minWidth: "180px",
      center: true,
      selector: (row) => row.namaProject,
    },
    {
      name: "Kode Project",
      minWidth: "180px",
      center: true,
      selector: (row) => row.kodeProject,
    },
    {
      name: "Sisa Hari",
      minWidth: "160px",
      sortable: true,
      center: true,
      selector: (row) => (!row.countdown ? "-" : `${row.countdown} Hari`),
      conditionalCellStyles: [
        {
          when: (row) => row.countdown <= 30,
          style: {
            color: "rgba(255, 0, 0, 100)",
            fontWeight: "bold",
            "&:hover": {
              cursor: "pointer",
            },
          },
        },
        {
          when: (row) => row.countdown < 61 && row.countdown > 30,
          style: {
            color: "rgba(255, 179, 0, 100)",
            fontWeight: "bold",
            "&:hover": {
              cursor: "pointer",
            },
          },
        },
      ],
    },
    {
      name: "Nomer PO",
      minWidth: "150px",
      center: true,
      selector: (row) => row.noPO,
    },
    {
      name: "Nama Partner",
      minWidth: "320px",
      center: true,
      selector: (row) => row.namaPartner,
    },
    {
      name: "Nama Material",
      minWidth: "200px",
      center: true,
      selector: (row) => row.namaMaterial,
    },
    {
      name: "Quantity",
      minWidth: "120px",
      center: true,
      selector: (row) => row.quantity,
    },
    {
      name: "Nominal",
      minWidth: "180px",
      center: true,
      selector: (row) => {
        const formattedValue = row.nominal.toLocaleString("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        });
        return `${formattedValue}`;
      },
    },
    {
      name: "TTD Kontrak",
      minWidth: "160px",
      center: true,
      selector: (row) => moment(row.ttdKontrak).format("DD MMMM YYYY"),
    },
    {
      name: "Durasi",
      minWidth: "160px",
      center: true,
      selector: (row) => (!row.durasi ? "-" : `${row.durasi} Bulan`),
    },
    {
      name: "Dateline",
      minWidth: "160px",
      center: true,
      selector: (row) => moment(row.dateline).format("DD MMMM YYYY"),
    },
    {
      name: "Status",
      minWidth: "200px",
      center: true,
      selector: (row) => row.statusPurchaseOrder,
      cell: (row) => {
        const statusStyle = getStatusStyle(row.statusPurchaseOrder);
        return (
          <div
            style={{
              fontWeight: statusStyle.fontWeight,
              backgroundColor: statusStyle.backgroundColor,
              color: statusStyle.textColor,
              padding: "10px",
              borderRadius: "5px",
            }}
          >
            {row.statusPurchaseOrder}
          </div>
        );
      },
    },

    {
      name: "Aksi",
      minWidth: "240px",
      center: true,
      selector: (row) => row.actions,
    },
  ];

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const handleCloseModal = () => {
    setIdProject("");
    setNomerPO("");
    setNamaMaterial("");
    setIdPartner("");
    setQuantity("");
    setNominal("");
    setTtdKontrak("");
    // durasi("");
    setDokumenFile("");
  };

  const handleCloseModalUpdatStatus = () => {
    setStatusPurchaseOrder("");
    setKeterangan("");
  };

  const handleCloseModalDokumenAkhir = () => {
    setNamaFile("");
    setDokumenAkhir("");
  };

  const formattedValue = (e) => {
    return e.toLocaleString("id-ID", {
      style: "currency",
      currency: "IDR",
      minimumFractionDigits: 0,
    });
  };

  const getStatusStyle = (status) => {
    switch (status) {
      case "Dibagikan":
        return {
          fontWeight: "bold",
          backgroundColor: "#B6F0FB",
          textColor: "#23CCEF",
        };
      case "Diproses":
        return {
          fontWeight: "bold",
          backgroundColor: "#D8C8F8",
          textColor: "#9368E9",
        };
      case "Dikirim":
        return {
          fontWeight: "bold",
          backgroundColor: "#FFE1B8",
          textColor: "#FFA534",
        };
      case "Diterima":
        return {
          fontWeight: "bold",
          backgroundColor: "#DBEFB5",
          textColor: "#87CB16",
        };
      case "Ditolak":
        return {
          fontWeight: "bold",
          backgroundColor: "#FEBEC0",
          textColor: "#FB404B",
        };
      default:
        return {};
    }
  };

  const submitpurchaseorder = (e) => {
    e.preventDefault();
    let datepo = new Date(ttdkontrak);
    let lastdate = new Date(
      datepo.setDate(datepo.getDate() + parseInt(dateline * 30))
    );
    let newDateline = moment(lastdate).format("YYYY-MM-DD");

    let formData = new FormData();
    formData.append("idProject", idProject._id),
      formData.append("noPO", nomerpo),
      formData.append("idPartner", idPartner._id),
      formData.append("namaMaterial", namamaterial),
      formData.append("quantity", quantity),
      formData.append("nominal", nominal),
      formData.append("ttdKontrak", ttdkontrak),
      formData.append("dateline", newDateline),
      formData.append("durasi", dateline),
      dokumenfile.map((val) => {
        formData.append("dokumenFile", val);
      });
    addPurchaseOrder(formData);
  };

  function submitupdatestatus(e) {
    e.preventDefault();
    if (statuspurchaseorder === "") {
      Swal.fire({
        title: "Status Harus Diisi",
        icon: "warning",
      });
      return;
    }
    updateStatusPurchaseOrder({
      idPO: idUpdateStatus,
      statusPurchaseOrder: statuspurchaseorder.value,
      keterangan: keterangan,
    });
  }

  const submitdokumenakhir = (e) => {
    e.preventDefault();
    if (namafile === "") {
      Swal.fire({
        title: "Nama File Harus Diisi",
        icon: "warning",
      });
      return;
    } else if (dokumenakhir === "") {
      Swal.fire({
        title: "Dokumen Harus Diisi",
        icon: "warning",
      });
      return;
    }
    let formData = new FormData();
    formData.append("idPO", idDokumenAkhir);
    formData.append("namaFile", namafile);
    dokumenakhir.forEach((val) => {
      formData.append("dokumenAkhir", val);
    });
    tambahDokumenAkhir(formData);
  };

  React.useEffect(() => {
    dataProject(dispatch);
    dataPurchaseOrder(dispatch);
    dataPartner(dispatch);
  }, []);

  return (
    <>
      <Container fluid>
        <Card>
          <Col md="12">
            <h2 className="title">Purchase Order</h2>
            <Card style={{ border: 0 }}>
              <Row
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Col md={"6"}>
                  {auth.role === "superadmin" ? (
                    <Button
                      className="btn-wd mr-1"
                      onClick={() => {
                        setmodalAddPO(true);
                      }}
                      variant="primary"
                      style={{ marginBottom: 20, marginTop: 20 }}
                    >
                      Tambah Purchase Order
                    </Button>
                  ) : null}
                  <Button
                    className="btn-wd mr-1"
                    onClick={() => {
                      downloadDataExcel();
                    }}
                    variant="success"
                    style={{
                      marginBottom: 20,
                      marginTop: 20,
                      marginLeft: 10,
                    }}
                  >
                    <i
                      className="fas fa-file-excel fa-lg"
                      style={{ marginRight: 10 }}
                    ></i>
                    Export Data
                  </Button>
                </Col>

                <Col md={"3"} style={{ marginTop: 20 }}>
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    name="singleStatus"
                    onChange={(value) => {
                      setFilterStatus(value.value);
                      setStatusPurchaseOrder(value);
                    }}
                    options={[
                      {
                        value: "",
                        label: "Pilih Status",
                        isDisabled: true,
                      },
                      {
                        value: "",
                        label: "Semua",
                      },
                      {
                        value: "Dibagikan",
                        label: "Dibagikan",
                      },
                      {
                        value: "Diproses",
                        label: "Diproses",
                      },
                      {
                        value: "Dikirim",
                        label: "Dikirim",
                      },
                      {
                        value: "Diterima",
                        label: "Diterima",
                      },
                      {
                        value: "Ditolak",
                        label: "Ditolak",
                      },
                    ]}
                    placeholder="Pilih Status"
                  />
                </Col>

                <Col md={"3"} style={{ marginTop: 20 }}>
                  <div>
                    <Form.Control
                      style={{
                        marginLeft: 1,
                      }}
                      type="text"
                      value={searchText}
                      onChange={handleSearch}
                      placeholder="Cari Berdasarkan Nama Partner"
                    />
                  </div>
                </Col>
              </Row>
            </Card>

            <Col md="12"></Col>
            <Row>
              <Col md={statusA} style={{ marginTop: 40 }}>
                <DataTable
                  columns={colPurchaseOrder}
                  data={listPurchaseOrder.filter(
                    (row) =>
                      row.statusPurchaseOrder
                        .toLowerCase()
                        .includes(filterStatus.toLowerCase()) &&
                      row.namaPartner
                        .toLowerCase()
                        .includes(searchText.toLowerCase())
                  )}
                  customStyles={customStyles}
                  pagination
                  paginationRowsPerPageOptions={[5, 10, 15, 20, 25]}
                  paginationPerPage={itemsPerPage}
                />
              </Col>
              {statusB !== "0" && (
                <Col
                  md={statusB}
                  style={{ maxHeight: "420px", overflowY: "scroll" }}
                >
                  <Button
                    className="btn-transparent mr-2"
                    type="button"
                    variant="danger"
                    size="md"
                    style={{ border: "none" }}
                    onClick={() => {
                      setStatusB("0");
                      setStatusA("12");
                    }}
                  >
                    <i className="fas fa-times"></i>
                  </Button>
                  <Card.Header>
                    <Card.Title as="h4" style={{ color: "black" }}>
                      Detail Status
                    </Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <Tab.Content>
                      <Row>
                        <Col sm="4">
                          <label
                            style={{
                              textTransform: "none",
                              fontSize: 14,
                              color: "black",
                            }}
                          >
                            Nama Project
                          </label>
                        </Col>
                        <Col sm="o">
                          <label
                            style={{
                              textTransform: "none",
                              fontSize: 14,
                              color: "black",
                            }}
                          >
                            :
                          </label>
                        </Col>
                        <Col sm="6">
                          <label
                            style={{
                              textTransform: "none",
                              fontSize: 14,
                              color: "black",
                            }}
                          >
                            {detailStatus.namaProject}
                          </label>
                        </Col>
                           
                      </Row>
                      <Row>
                        <Col sm="4">
                          <label
                            style={{
                              textTransform: "none",
                              fontSize: 14,
                              color: "black",
                            }}
                          >
                            Kode Project
                          </label>
                        </Col>
                        <Col sm="o">
                          <label
                            style={{
                              textTransform: "none",
                              fontSize: 14,
                              color: "black",
                            }}
                          >
                            :
                          </label>
                        </Col>
                        <Col sm="6">
                          <label
                            style={{
                              textTransform: "none",
                              fontSize: 14,
                              color: "black",
                            }}
                          >
                            {detailStatus.kodeProject}
                          </label>
                        </Col>
                           
                      </Row>
                      <Row>
                        <Col sm="4">
                          <label
                            style={{
                              textTransform: "none",
                              fontSize: 14,
                              color: "black",
                            }}
                          >
                            Sisa Hari
                          </label>
                        </Col>
                        <Col sm="o">
                          <label
                            style={{
                              textTransform: "none",
                              fontSize: 14,
                              color: "black",
                            }}
                          >
                            :
                          </label>
                        </Col>
                        <Col sm="6">
                          <label
                            style={{
                              textTransform: "none",
                              fontSize: 14,
                              color: "black",
                            }}
                          >
                            {`${selisih} Hari`}
                          </label>
                        </Col>
                           
                      </Row>
                      <Row>
                        <Col sm="4">
                          <label
                            style={{
                              textTransform: "none",
                              fontSize: 14,
                              color: "black",
                            }}
                          >
                            Nomer PO
                          </label>
                        </Col>
                        <Col sm="o">
                          <label
                            style={{
                              textTransform: "none",
                              fontSize: 14,
                              color: "black",
                            }}
                          >
                            :
                          </label>
                        </Col>
                        <Col sm="6">
                          <label
                            style={{
                              textTransform: "none",
                              fontSize: 14,
                              color: "black",
                            }}
                          >
                            {detailStatus.noPO}
                          </label>
                        </Col>
                           
                      </Row>
                      <Row>
                        <Col sm="4">
                          <label
                            style={{
                              textTransform: "none",
                              fontSize: 14,
                              color: "black",
                            }}
                          >
                            Nama Partner
                          </label>
                        </Col>
                        <Col sm="o">
                          <label
                            style={{
                              textTransform: "none",
                              fontSize: 14,
                              color: "black",
                            }}
                          >
                            :
                          </label>
                        </Col>
                        <Col sm="6">
                          <label
                            style={{
                              textTransform: "none",
                              fontSize: 14,
                              color: "black",
                            }}
                          >
                            {detailStatus.namaPartner}
                          </label>
                        </Col>
                           
                      </Row>
                      <Row>
                        <Col sm="4">
                          <label
                            style={{
                              textTransform: "none",
                              fontSize: 14,
                              color: "black",
                            }}
                          >
                            Nama Material
                          </label>
                        </Col>
                        <Col sm="o">
                          <label
                            style={{
                              textTransform: "none",
                              fontSize: 14,
                              color: "black",
                            }}
                          >
                            :
                          </label>
                        </Col>
                        <Col sm="6">
                          <label
                            style={{
                              textTransform: "none",
                              fontSize: 14,
                              color: "black",
                            }}
                          >
                            {detailStatus.namaMaterial}
                          </label>
                        </Col>
                           
                      </Row>
                      <Row>
                        <Col sm="4">
                          <label
                            style={{
                              textTransform: "none",
                              fontSize: 14,
                              color: "black",
                            }}
                          >
                            Quantity
                          </label>
                        </Col>
                        <Col sm="o">
                          <label
                            style={{
                              textTransform: "none",
                              fontSize: 14,
                              color: "black",
                            }}
                          >
                            :
                          </label>
                        </Col>
                        <Col sm="6">
                          <label
                            style={{
                              textTransform: "none",
                              fontSize: 14,
                              color: "black",
                            }}
                          >
                            {detailStatus.quantity}
                          </label>
                        </Col>
                           
                      </Row>
                      <Row>
                        <Col sm="4">
                          <label
                            style={{
                              textTransform: "none",
                              fontSize: 14,
                              color: "black",
                            }}
                          >
                            Nominal
                          </label>
                        </Col>
                        <Col sm="o">
                          <label
                            style={{
                              textTransform: "none",
                              fontSize: 14,
                              color: "black",
                            }}
                          >
                            :
                          </label>
                        </Col>
                        <Col sm="6">
                          <label
                            style={{
                              textTransform: "none",
                              fontSize: 14,
                              color: "black",
                            }}
                          >
                            {formattedValue(detailStatus.nominal)}
                          </label>
                        </Col>
                           
                      </Row>
                      <Row>
                        <Col sm="4">
                          <label
                            style={{
                              textTransform: "none",
                              fontSize: 14,
                              color: "black",
                            }}
                          >
                            TTD Kontrak
                          </label>
                        </Col>
                        <Col sm="o">
                          <label
                            style={{
                              textTransform: "none",
                              fontSize: 14,
                              color: "black",
                            }}
                          >
                            :
                          </label>
                        </Col>
                        <Col sm="6">
                          <label
                            style={{
                              textTransform: "none",
                              fontSize: 14,
                              color: "black",
                            }}
                          >
                            {moment(detailStatus.ttdKontrak).format(
                              "DD MMMM YYYY"
                            )}
                          </label>
                        </Col>
                           
                      </Row>
                      <Row>
                        <Col sm="4">
                          <label
                            style={{
                              textTransform: "none",
                              fontSize: 14,
                              color: "black",
                            }}
                          >
                            Durasi
                          </label>
                        </Col>
                        <Col sm="o">
                          <label
                            style={{
                              textTransform: "none",
                              fontSize: 14,
                              color: "black",
                            }}
                          >
                            :
                          </label>
                        </Col>
                        <Col sm="6">
                          <label
                            style={{
                              textTransform: "none",
                              fontSize: 14,
                              color: "black",
                            }}
                          >
                            {`${detailStatus.durasi} Bulan`}
                          </label>
                        </Col>
                           
                      </Row>
                      <Row>
                        <Col sm="4">
                          <label
                            style={{
                              textTransform: "none",
                              fontSize: 14,
                              color: "black",
                            }}
                          >
                            Dateline
                          </label>
                        </Col>
                        <Col sm="o">
                          <label
                            style={{
                              textTransform: "none",
                              fontSize: 14,
                              color: "black",
                            }}
                          >
                            :
                          </label>
                        </Col>
                        <Col sm="6">
                          <label
                            style={{
                              textTransform: "none",
                              fontSize: 14,
                              color: "black",
                            }}
                          >
                            {moment(detailStatus.dateline).format(
                              "DD MMMM YYYY"
                            )}
                          </label>
                        </Col>
                           
                      </Row>
                      <Card.Title
                        as="h4"
                        style={{
                          marginTop: 14,
                          marginBottom: 4,
                          color: "black",
                        }}
                      >
                        History {`(${detailStatus.history.length})`}
                      </Card.Title>
                      <Card
                        style={{
                          maxHeight: "300px",
                          overflowY: "scroll",
                          padding: 10,
                        }}
                      >
                        {detailStatus.history.map((val) => {
                          return (
                            <Tab.Content
                              style={{
                                borderWidth: 1,
                                borderRadius: 4,
                                margin: 15,
                                padding: 10,
                                boxShadow: "4px 4px 4px 4px rgba(0,0,0,0.30)",
                              }}
                            >
                              <Row>
                                <Col sm="4">
                                  <label
                                    style={{
                                      textTransform: "none",
                                      fontSize: 14,
                                      color: "black",
                                    }}
                                  >
                                    Tanggal
                                  </label>
                                </Col>
                                <Col sm="o">
                                  <label
                                    style={{
                                      textTransform: "none",
                                      fontSize: 14,
                                      color: "black",
                                    }}
                                  >
                                    :
                                  </label>
                                </Col>
                                <Col sm="6">
                                  <label
                                    style={{
                                      textTransform: "none",
                                      fontSize: 14,
                                      color: "black",
                                    }}
                                  >
                                    {moment(val.tanggal).format("DD MMMM YYYY")}
                                  </label>
                                </Col>
                                   
                              </Row>
                              <Row>
                                <Col sm="4">
                                  <label
                                    style={{
                                      textTransform: "none",
                                      fontSize: 14,
                                      color: "black",
                                    }}
                                  >
                                    Status
                                  </label>
                                </Col>
                                <Col sm="o">
                                  <label
                                    style={{
                                      textTransform: "none",
                                      fontSize: 14,
                                      color: "black",
                                    }}
                                  >
                                    :
                                  </label>
                                </Col>
                                <Col sm="6">
                                  <label
                                    style={{
                                      textTransform: "none",
                                      fontSize: 14,
                                      color: "black",
                                    }}
                                  >
                                    {val.status}
                                  </label>
                                </Col>
                                   
                              </Row>
                              <Row>
                                <Col sm="4">
                                  <label
                                    style={{
                                      textTransform: "none",
                                      fontSize: 14,
                                      color: "black",
                                    }}
                                  >
                                    Keterangan
                                  </label>
                                </Col>
                                <Col sm="o">
                                  <label
                                    style={{
                                      textTransform: "none",
                                      fontSize: 14,
                                      color: "black",
                                    }}
                                  >
                                    :
                                  </label>
                                </Col>
                                <Col sm="6">
                                  <label
                                    style={{
                                      textTransform: "none",
                                      fontSize: 14,
                                      color: "black",
                                    }}
                                  >
                                    {val.keterangan === ""
                                      ? "-"
                                      : val.keterangan}
                                  </label>
                                </Col>
                                   
                              </Row>
                            </Tab.Content>
                          );
                        })}
                      </Card>
                      <Row>
                        <Col sm="4">
                          <label
                            style={{
                              marginTop: 20,
                              textTransform: "none",
                              fontSize: 14,
                              color: "black",
                            }}
                          >
                            Dokumen Awal
                          </label>
                        </Col>
                        <Col sm="o">
                          <label
                            style={{
                              marginTop: 20,
                              textTransform: "none",
                              fontSize: 14,
                              color: "black",
                            }}
                          >
                            :
                          </label>
                        </Col>
                        <Col sm="6">
                          <label
                            style={{
                              textTransform: "none",
                              fontSize: 14,
                              color: "black",
                            }}
                          >
                            <Button
                              className="btn"
                              style={{ marginTop: 10 }}
                              onClick={() => {
                                // console.log(detailStatus.dokumenFile, "AAAAA");
                                setPreviewPDF(true);
                              }}
                              variant="info"
                            >
                              Preview
                            </Button>
                          </label>
                        </Col>
                           
                      </Row>
                      <Row>
                        <Col sm="4">
                          <label
                            style={{
                              marginTop: 20,
                              textTransform: "none",
                              fontSize: 14,
                              color: "black",
                            }}
                          >
                            Dokumen Akhir
                          </label>
                        </Col>
                        <Col sm="o">
                          <label
                            style={{
                              marginTop: 20,
                              textTransform: "none",
                              fontSize: 14,
                              color: "black",
                            }}
                          >
                            :
                          </label>
                        </Col>
                           
                      </Row>
                      {detailStatus.dokumenAkhir.length > 0 ? (
                        detailStatus.dokumenAkhir.map((val) => (
                          <Tab.Content
                            style={{
                              borderWidth: 1,
                              borderRadius: 4,
                              margin: 15,
                              padding: 10,
                              boxShadow: "4px 4px 4px 0px rgba(0,0,0,0.30)",
                            }}
                          >
                            <Row>
                              <Col sm="4">
                                <label
                                  style={{
                                    textTransform: "none",
                                    fontSize: 14,
                                    color: "black",
                                  }}
                                >
                                  Nama File
                                </label>
                              </Col>
                              <Col sm="o">
                                <label
                                  style={{
                                    textTransform: "none",
                                    fontSize: 14,
                                    color: "black",
                                  }}
                                >
                                  :
                                </label>
                              </Col>
                              <Col sm="6">
                                <label
                                  style={{
                                    textTransform: "none",
                                    fontSize: 14,
                                    color: "black",
                                  }}
                                >
                                  {val.namafile}
                                </label>
                              </Col>
                            </Row>
                            <Row>
                              <Col sm="4">
                                <label
                                  style={{
                                    marginTop: 20,
                                    textTransform: "none",
                                    fontSize: 14,
                                    color: "black",
                                  }}
                                >
                                  Dokumen Akhir
                                </label>
                              </Col>
                              <Col sm="o">
                                <label
                                  style={{
                                    marginTop: 20,
                                    textTransform: "none",
                                    fontSize: 14,
                                    color: "black",
                                  }}
                                >
                                  :
                                </label>
                              </Col>
                              <Col sm="6">
                                <label
                                  style={{
                                    textTransform: "none",
                                    fontSize: 14,
                                    color: "black",
                                  }}
                                >
                                  <Button
                                    className="btn"
                                    style={{ marginTop: 10 }}
                                    onClick={() => {
                                      setDokumenAkhir(val.dokumen);
                                      setPreviewPDFAkhir(true);
                                    }}
                                    variant="info"
                                  >
                                    Preview
                                  </Button>
                                </label>
                              </Col>
                            </Row>
                          </Tab.Content>
                        ))
                      ) : (
                        <label
                          style={{
                            textTransform: "none",
                            fontSize: 14,
                            color: "black",
                          }}
                        >
                          -
                        </label>
                      )}
                         
                    </Tab.Content>
                  </Card.Body>
                </Col>
              )}
            </Row>
          </Col>
        </Card>
      </Container>

      <Modal
        size="lg"
        show={modalAddPO}
        onHide={() => {
          handleCloseModal();
          setmodalAddPO(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="12">
              <Form action="" className="form" method="">
                <Card>
                  <Card.Header>
                    <Card.Header>
                      <Card.Title as="h4" style={{ fontWeight: "bold" }}>
                        Tambah Purchase Order
                      </Card.Title>
                    </Card.Header>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col sm="12">
                        <Form.Group>
                          <label
                            style={{
                              fontWeight: "bold",
                              fontSize: 14,
                              color: "grey",
                              textTransform: "none",
                            }}
                          >
                            Nama Project
                          </label>
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="singleProject"
                            onChange={(value) => setIdProject(value)}
                            // value={idProject}
                            options={[
                              {
                                value: "",
                                label: "Pilih Nama Project",
                                isDisabled: true,
                              },
                              ...listProjectDropDownBaru,
                            ]}
                            placeholder="Pilih Nama Project"
                          />
                        </Form.Group>
                      </Col>
                      <Col sm="12">
                        <Form.Group>
                          <label
                            style={{
                              fontWeight: "bold",
                              fontSize: 14,
                              color: "grey",
                              textTransform: "none",
                            }}
                          >
                            Nomer PO
                          </label>
                          <Form.Control
                            onChange={(e) => {
                              setNomerPO(e.target.value);
                            }}
                            type="text"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col sm="12">
                        <Form.Group>
                          <label
                            style={{
                              fontWeight: "bold",
                              fontSize: 14,
                              color: "grey",
                              textTransform: "none",
                            }}
                          >
                            Nama Material
                          </label>
                          <Form.Control
                            onChange={(e) => {
                              setNamaMaterial(e.target.value);
                            }}
                            type="text"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col sm="12">
                        <Form.Group>
                          <label
                            style={{
                              fontWeight: "bold",
                              fontSize: 14,
                              color: "grey",
                              textTransform: "none",
                            }}
                          >
                            Nama Partner
                          </label>
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="singleRole"
                            onChange={(value) => setIdPartner(value)}
                            // value={idPartner}
                            options={[
                              {
                                value: "",
                                label: "Pilih Nama Partner",
                                isDisabled: true,
                              },
                              ...listPartnerDropDownBaru,
                            ]}
                            placeholder="Pilih Nama Partner"
                          />
                        </Form.Group>
                      </Col>
                      <Col sm="12">
                        <Form.Group>
                          <label
                            style={{
                              fontWeight: "bold",
                              fontSize: 14,
                              color: "grey",
                              textTransform: "none",
                            }}
                          >
                            Quantity
                          </label>
                          <Form.Control
                            min={1}
                            onChange={(e) => {
                              setQuantity(e.target.value);
                            }}
                            type="number"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col sm="12">
                        <Form.Group>
                          <label
                            style={{
                              fontWeight: "bold",
                              fontSize: 14,
                              color: "grey",
                              textTransform: "none",
                            }}
                          >
                            Nominal
                          </label>
                          <Form.Control
                            min={1}
                            onChange={(e) => {
                              setNominal(e.target.value);
                            }}
                            type="number"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col sm="12">
                        <Form.Group>
                          <label
                            style={{
                              fontWeight: "bold",
                              fontSize: 14,
                              color: "grey",
                              textTransform: "none",
                            }}
                          >
                            Tanda Tangan Kontrak
                          </label>
                          <Form.Control
                            onChange={(e) => {
                              setTtdKontrak(e.target.value);
                            }}
                            type="date"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col sm="12">
                        <Form.Group>
                          <label
                            style={{
                              fontWeight: "bold",
                              fontSize: 14,
                              color: "grey",
                              textTransform: "none",
                            }}
                          >
                            Durasi
                          </label>
                          <Row>
                            <Col sm="10">
                              <Form.Control
                                min={1}
                                onChange={(e) => {
                                  setDateline(e.target.value);
                                }}
                                type="number"
                              ></Form.Control>
                            </Col>
                            <Col>
                              <span
                                className="input-group-text"
                                id="basic-addon2"
                              >
                                Bulan
                              </span>
                            </Col>
                          </Row>
                        </Form.Group>
                      </Col>
                      <Col sm="12">
                        <Form.Group>
                          <label
                            style={{
                              fontWeight: "bold",
                              fontSize: 14,
                              color: "grey",
                              textTransform: "none",
                            }}
                          >
                            Dokumen (PDF)
                          </label>
                          <Form.Control
                            // value={detailStatus.dokumenFile}
                            onChange={(e) => {
                              setDokumenFile([
                                ...dokumenfile,
                                ...Array.from(e.target.files),
                              ]);
                            }}
                            type="file"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Button
                      className="btn-fill pull-right"
                      type="submit"
                      variant="info"
                      onClick={submitpurchaseorder}
                    >
                      Submit
                    </Button>
                    <div className="clearfix"></div>
                  </Card.Body>
                </Card>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <Modal
        size="lg"
        show={modalUpdateStatus}
        onHide={() => {
          handleCloseModalUpdatStatus();
          setModalUpdateStatus(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="12">
              <Form action="" className="form" method="">
                <Card>
                  <Card.Header>
                    <Card.Header>
                      <Card.Title as="h4">
                        Update Status Purchase Order
                      </Card.Title>
                    </Card.Header>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col sm="12">
                        <Form.Group>
                          <label>Status</label>
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="singleStatus"
                            onChange={(value) => setStatusPurchaseOrder(value)}
                            options={[
                              {
                                value: "",
                                label: "Pilih Status",
                                isDisabled: true,
                              },
                              {
                                value: "Diproses",
                                label: "Barang Diproses",
                              },
                              {
                                value: "Dikirim",
                                label: "Barang Telah Dikirim",
                              },
                              {
                                value: "Diterima",
                                label: "Barang Telah Diterima",
                              },
                              {
                                value: "Ditolak",
                                label: "Barang Ditolak",
                              },
                            ]}
                            placeholder="Pilih Status"
                          />
                        </Form.Group>
                      </Col>
                      <Col sm="12">
                        <Form.Group>
                          <label>Keterangan</label>
                          <textarea
                            // value={keterangan}
                            onChange={(e) => {
                              setKeterangan(e.target.value);
                            }}
                            rows={4}
                            className="form-control"
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Button
                      className="btn-fill pull-right"
                      type="submit"
                      variant="info"
                      onClick={submitupdatestatus}
                    >
                      Submit
                    </Button>
                    <div className="clearfix"></div>
                  </Card.Body>
                </Card>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <Modal
        size="lg"
        show={previewPDF}
        onHide={() => {
          setPreviewPDF(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="12">
              <Form action="" className="form" method="">
                <Card>
                  <Card.Header>
                    <Card.Header>
                      <Card.Title as="h4">Preview Dokumen Awal</Card.Title>
                    </Card.Header>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col sm="12">
                        <Card.Body
                          style={{ maxHeight: "700px", overflowY: "scroll" }}
                        >
                          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                            <Viewer fileUrl={detailStatus.dokumenFile}></Viewer>
                          </Worker>
                        </Card.Body>
                      </Col>
                    </Row>
                    <div className="clearfix"></div>
                  </Card.Body>
                </Card>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <Modal
        size="lg"
        show={modalDokumenAkhir}
        onHide={() => {
          handleCloseModalDokumenAkhir();
          setModalDokumenAkhir(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="12">
              <Form action="" className="form" method="">
                <Card>
                  <Card.Header>
                    <Card.Header>
                      <Card.Title as="h4">Tambah Dokumen Akhir</Card.Title>
                    </Card.Header>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col sm="12">
                        <Form.Group>
                          <label>Nama File</label>
                          <Form.Control
                            onChange={(e) => {
                              setNamaFile(e.target.value);
                            }}
                            type="text"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col sm="12">
                        <Form.Group>
                          <label>Dokumen (PDF)</label>
                          <Form.Control
                            onChange={(e) => {
                              setDokumenAkhir([
                                ...dokumenakhir,
                                ...Array.from(e.target.files),
                              ]);
                            }}
                            type="file"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Button
                      className="btn-fill pull-right"
                      type="submit"
                      variant="info"
                      onClick={submitdokumenakhir}
                    >
                      Submit
                    </Button>
                    <div className="clearfix"></div>
                  </Card.Body>
                </Card>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <Modal
        size="lg"
        show={previewPDFAkhir}
        onHide={() => {
          setPreviewPDFAkhir(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="12">
              <Form action="" className="form" method="">
                <Card>
                  <Card.Header>
                    <Card.Header>
                      <Card.Title as="h4">Preview Dokumen Akhir</Card.Title>
                    </Card.Header>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col sm="12">
                        <Card.Body
                          style={{ maxHeight: "700px", overflowY: "scroll" }}
                        >
                          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                            <Viewer fileUrl={dokumenakhir}></Viewer>
                          </Worker>
                        </Card.Body>
                      </Col>
                    </Row>
                    <div className="clearfix"></div>
                  </Card.Body>
                </Card>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default TambahPurchaseOrder;
