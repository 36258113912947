import { baseAxios, errorHandler } from "../index";
import Swal from "sweetalert2";

export function addUser(data) {
  Swal.fire({
    title: "Loading ...",
    didOpen() {
      Swal.showLoading();
    },
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
  });
  baseAxios
    .post("/webUser/tambahUser", data, {
      headers: { token: localStorage.getItem("token") },
    })
    .then(async (respon) => {
      if (respon.status === 200) {
        Swal.fire({
          title: respon.data.message,
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          window.location.reload();
        });
      } else {
        Swal.fire({
          title: respon.data.message,
          icon: "error",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    })
    .catch(errorHandler);
}

export function dataUsers(dispatch) {
  baseAxios
    .get("/webUser/dataUsers", {
      headers: { token: localStorage.getItem("token") },
    })
    .then((respon) => {
      dispatch({ type: "SET_GETALL_DATAUSERS", data: respon.data.data });
    })
    .catch(errorHandler);
}

