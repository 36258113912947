const initialState = {
  dataPartner: [],
  getDataPartner: [],
};

const dataPartnerReducer = (state = initialState, action) => {
  let { type, data } = action;

  switch (type) {
    case "SET_GETALL_DATAPARTNER":
      return {
        ...state,
        dataPartner: data,
      };
    default:
      return state;
  }
};

export default dataPartnerReducer;
