const initialState = {
  dataPurchaseOrder: [],
  totalPurchaseOrder: [],
};

const purchaseOrderReducer = (state = initialState, action) => {
  let { type, data } = action;

  switch (type) {
    case "SET_GETALL_PURCHASEORDER":
      return {
        ...state,
        dataPurchaseOrder: data,
      };
    case "SET_GETALL_TOTALPURCHASEORDER":
      return {
        ...state,
        totalPurchaseOrder: data,
      };
    default:
      return state;
  }
};

export default purchaseOrderReducer;
