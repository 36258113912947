import React from "react";

// react-bootstrap components
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Modal,
  Form,
  OverlayTrigger,
  Table,
  Tooltip,
  Image,
  FormGroup,
} from "react-bootstrap";
import Select from "react-select";
// core components
import ReactTable from "components/ReactTable/ReactTable.js";
import DataTable from "react-data-table-component";
import {
  dataPartner,
  totalPurchaseOrder,
  dataPurchaseOrder,
  dataUsers,
  dataProject,
} from "../../stores";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactDatetime from "react-datetime";
import moment from "moment";
import { setConstantValue } from "typescript";
import { CardBody, Label } from "reactstrap";
import CurrencyInput from "react-currency-input-field";

function TotalPurchaseOrder() {
  const dispatch = useDispatch();
  const date = new Date();

  const totalPO = useSelector((state) => state.purchaseOrderReducer);

  const [searchText, setSearchText] = React.useState("");
  const [listTotalPO, setListPO] = React.useState([]);
  const [detailPO, setDetailPO] = React.useState([]);
  const [modalListPO, setModalListPO] = React.useState(false);

  React.useEffect(() => {
    let tmp =
      totalPO.totalPurchaseOrder &&
      totalPO.totalPurchaseOrder.map((val, index) => {
        // console.log(val.listPo, "LISTTTTTTTTT");
        let totalDiterima = 0;
        let totalDikirim = 0;
        let totalDiproses = 0;
        let totalDibagikan = 0;
        let totalDitolak = 0;
        val.listPo.map((value) => {
          if (value.statusPurchaseOrder === "Diterima") {
            totalDiterima++;
          }
          if (value.statusPurchaseOrder === "Dikirim") {
            totalDikirim++;
          }
          if (value.statusPurchaseOrder === "Diproses") {
            totalDiproses++;
          }
          if (value.statusPurchaseOrder === "Dibagikan") {
            totalDibagikan++;
          }
          if (value.statusPurchaseOrder === "Ditolak") {
            totalDitolak++;
          }
        });

        return {
          ...val,
          progress: (
            <>
              <div
                style={{
                  fontWeight: "bold",
                  marginBottom: "5px",
                  marginTop: "18px",
                  color: "#23CCEF",
                }}
              >
                Dibagikan: {((totalDibagikan / val.jumlahPo) * 100).toFixed(1)}%
              </div>
              <div
                style={{
                  color: "#9368E9",
                  fontWeight: "bold",
                  marginBottom: "8px",
                }}
              >
                Diproses: {((totalDiproses / val.jumlahPo) * 100).toFixed(1)}%
              </div>
              <div
                style={{
                  color: "#FFA534",
                  fontWeight: "bold",
                  marginBottom: "8px",
                }}
              >
                Dikirim: {((totalDikirim / val.jumlahPo) * 100).toFixed(1)}%
              </div>
              <div
                style={{
                  color: "#87CB16",
                  fontWeight: "bold",
                  marginBottom: "8px",
                }}
              >
                Diterima: {((totalDiterima / val.jumlahPo) * 100).toFixed(1)}%
              </div>
              <div
                style={{
                  color: "#FB404B",
                  fontWeight: "bold",
                  marginBottom: "18px",
                }}
              >
                Ditolak: {((totalDitolak / val.jumlahPo) * 100).toFixed(1)}%
              </div>
            </>
          ),
          // ((totalSelainDiterima / val.jumlahPo) * 100).toFixed(2),
          action: (
            <div className="actions-right">
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>Detail</Tooltip>}
              >
                <Button
                  className="mr-2"
                  variant="info"
                  style={{
                    marginTop: 5,
                    padding: "6px 15px",
                    fontSize: "18px",
                  }}
                  onClick={() => {
                    // console.log(val, "BBBBBBBBBBBBBBB");
                    setModalListPO(!modalListPO);
                    setDetailPO(val.listPo);
                  }}
                >
                  <i class="fas fa-info"></i>
                </Button>
              </OverlayTrigger>
            </div>
          ),
        };
      });

    setListPO(tmp);
  }, [totalPO.totalPurchaseOrder]);

  React.useEffect(() => {
    totalPurchaseOrder(dispatch);
  }, []);

  const customStyles = {
    rows: {
      style: {
        // height: 100, // override the row height
      },
    },
    headRow: {
      style: {
        minHeight: 35,
        backgroundColor: "#F8F8F8",
        //fontFamily: "Poppins",
        justifyContent: "center",
      },
    },
    headCells: {
      style: {
        minHeight: 50,
        fontWeight: "bold",
        fontSize: 14,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
    cells: {
      style: {
        minHeight: 50,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
  };

  const customStyles2 = {
    rows: {
      style: {
        // height: 100, // override the row height
      },
    },
    headRow: {
      style: {
        minHeight: 35,
        backgroundColor: "#F8F8F8",
        //fontFamily: "Poppins",
        justifyContent: "center",
      },
    },
    headCells: {
      style: {
        minHeight: 50,
        fontWeight: "bold",
        fontSize: 14,
      },
    },
    cells: {
      style: {
        minHeight: 50,
      },
    },
  };

  const colTotalPurchaseOrder = [
    {
      name: "No",
      width: "80px",
      center: true,
      selector: (row) => listTotalPO.indexOf(row) + 1,
    },
    {
      name: "Nama Partner",
      minWidth: "280px",
      center: true,
      selector: (row) => row.namaPartner,
    },

    {
      name: "Jumlah PO",
      minWidth: "120px",
      center: true,
      selector: (row) => row.jumlahPo,
    },
    {
      name: "Nominal",
      minWidth: "180px",
      center: true,
      selector: (row) => {
        const formattedValue = row.jumlahNominal.toLocaleString("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        });
        return `${formattedValue}`;
      },
    },
    {
      name: "Progress",
      minWidth: "160px",
      center: true,
      selector: (row) => row.progress,
    },
    {
      name: "Aksi",
      minWidth: "120px",
      center: true,
      selector: (row) => row.action,
    },
  ];

  const colDetailPO = [
    {
      name: "Nama Project",
      width: "140px",
      center: true,
      selector: (row) => row.namaproject.namaProject,
    },
    {
      name: "Kode Project",
      minWidth: "120px",
      center: true,
      selector: (row) => row.namaproject.kodeProject,
    },
    {
      name: "Nomer PO",
      minWidth: "180px",
      center: true,
      selector: (row) => row.noPO,
    },
    {
      name: "Status",
      minWidth: "140px",
      center: true,
      selector: (row) => row.statusPurchaseOrder,
      cell: (row) => {
        const statusStyle = getStatusStyle(row.statusPurchaseOrder);
        return (
          <div
            style={{
              fontWeight: statusStyle.fontWeight,
              backgroundColor: statusStyle.backgroundColor,
              color: statusStyle.textColor,
              padding: "10px",
              borderRadius: "5px",
            }}
          >
            {row.statusPurchaseOrder}
          </div>
        );
      },
    },
  ];

  const getStatusStyle = (status) => {
    switch (status) {
      case "Dibagikan":
        return {
          fontWeight: "bold",
          backgroundColor: "#B6F0FB",
          textColor: "#23CCEF",
        };
      case "Diproses":
        return {
          fontWeight: "bold",
          backgroundColor: "#D8C8F8",
          textColor: "#9368E9",
        };
      case "Dikirim":
        return {
          fontWeight: "bold",
          backgroundColor: "#FFE1B8",
          textColor: "#FFA534",
        };
      case "Diterima":
        return {
          fontWeight: "bold",
          backgroundColor: "#DBEFB5",
          textColor: "#87CB16",
        };
      case "Ditolak":
        return {
          fontWeight: "bold",
          backgroundColor: "#FEBEC0",
          textColor: "#FB404B",
        };
      default:
        return {};
    }
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  return (
    <>
      <Container fluid>
        {/* <Button
          className="btn-wd mr-1"
          onClick={() => {
            setModal(true);
          }}
          variant="primary"
        >
          Pengajuan Project
        </Button> */}

        <Card>
          <Col md="12">
            <h2 className="title">Total Purchase Order</h2>

            <Col md={"4"}>
              <Form.Control
                style={{
                  marginLeft: 1,
                }}
                type="text"
                value={searchText}
                onChange={handleSearch}
                placeholder="Cari Berdasarkan Nama Partner"
              />
                        
            </Col>

            <Row>
              <Col md="12">
                <DataTable
                  columns={colTotalPurchaseOrder}
                  data={listTotalPO.filter((row) =>
                    row.namaPartner
                      .toLowerCase()
                      .includes(searchText.toLowerCase())
                  )}
                  customStyles={customStyles}
                  pagination
                />
              </Col>
            </Row>
          </Col>
        </Card>
      </Container>
      <Modal
        size="lg"
        show={modalListPO}
        onHide={() => {
          setModalListPO(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="12">
              <Form action="" className="form" method="">
                <Card>
                  <Card.Header>
                    <Card.Header>
                      <Card.Title as="h4" style={{ fontWeight: "bold" }}>
                        Detail List Purchase Order
                      </Card.Title>
                    </Card.Header>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col md="12">
                        <DataTable
                          columns={colDetailPO}
                          data={detailPO}
                          customStyles2={customStyles2}
                          pagination
                        />
                      </Col>
                    </Row>
                    <div className="clearfix"></div>
                  </Card.Body>
                </Card>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default TotalPurchaseOrder;
