import { baseAxios, errorHandler } from "../index";
import Swal from "sweetalert2";

export function addPurchaseOrder(data) {
  Swal.fire({
    title: "Loading ...",
    didOpen() {
      Swal.showLoading();
    },
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
  });
  baseAxios
    .post("/webPurchaseOrder/tambahPurchaseOrder", data, {
      headers: { token: localStorage.getItem("token") },
    })
    .then(async (respon) => {
      if (respon.status === 200) {
        Swal.fire({
          title: respon.data.message,
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          window.location.reload();
        });
      } else {
        Swal.fire({
          title: respon.data.message,
          icon: "error",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    })
    .catch(errorHandler);
}
export function updateStatusPurchaseOrder(data) {
  Swal.fire({
    title: "Loading ...",
    didOpen() {
      Swal.showLoading();
    },
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
  });
  baseAxios
    .post("/webPurchaseOrder/updateStatusPO", data, {
      headers: { token: localStorage.getItem("token") },
    })
    .then(async (respon) => {
      if (respon.status === 200) {
        Swal.fire({
          title: respon.data.message,
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          window.location.reload();
        });
      } else {
        Swal.fire({
          title: respon.data.message,
          icon: "error",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    })
    .catch(errorHandler);
}

export function tambahDokumenAkhir(data) {
  Swal.fire({
    title: "Loading ...",
    didOpen() {
      Swal.showLoading();
    },
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
  });
  baseAxios
    .post("/webPurchaseOrder/tambahDokumenAkhir", data, {
      headers: { token: localStorage.getItem("token") },
    })
    .then(async (respon) => {
      if (respon.status === 200) {
        Swal.fire({
          title: respon.data.message,
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          window.location.reload();
        });
      } else {
        Swal.fire({
          title: respon.data.message,
          icon: "error",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    })
    .catch(errorHandler);
}

export async function dataPurchaseOrder(dispatch) {
  try {
    const response = await baseAxios.get(
      "/webPurchaseOrder/dataPurchaseOrder",
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    dispatch({
      type: "SET_GETALL_PURCHASEORDER",
      data: response.data,
    });
    console.log(response, "DATA PURCHASE ORDER");
    return response;
  } catch (err) {
    return err.response;
  }
}

// export function dataPurchaseOrder(dispatch) {
//   baseAxios
//     .get("/webPurchaseOrder/dataPurchaseOrder", {
//       headers: { token: localStorage.getItem("token") },
//     })

//     .then((respon) => {
//       dispatch({ type: "SET_GETALL_PURCHASEORDER", data: respon.data });
//     })
//     .catch(errorHandler);
// }

export function totalPurchaseOrder(dispatch) {
  baseAxios
    .get("/webPurchaseOrder/totalPurchaseOrder", {
      headers: { token: localStorage.getItem("token") },
    })

    .then((respon) => {
      dispatch({
        type: "SET_GETALL_TOTALPURCHASEORDER",
        data: respon.data.data,
      });
    })
    .catch(errorHandler);
}

export function downloadDataExcel(data) {
  baseAxios
    .get("/webPurchaseOrder/downloadDataExcel", {
      headers: { token: localStorage.getItem("token") },
      responseType: "blob",
    })
    .then(async (respon) => {
      // console.log(respon.data, "?????????????");
      const url = window.URL.createObjectURL(new Blob([respon.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Data Purchase Order.xlsx`);
      document.body.appendChild(link);
      link.click();
      Swal.close();
    })
    .catch((err) => {
      // console.log(err, "gagal");
    });
}
