import React from "react";

// react-bootstrap components
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Modal,
  Form,
  Table,
  Image,
  FormGroup,
} from "react-bootstrap";
import Swal from "sweetalert2";
import Select from "react-select";
// core components
import ReactTable from "components/ReactTable/ReactTable.js";
import DataTable from "react-data-table-component";
import { dataPartner, tambahPartner } from "../../stores";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactDatetime from "react-datetime";
import moment from "moment";
import { setConstantValue } from "typescript";
import { CardBody } from "reactstrap";

function TambahPartner() {
  const dispatch = useDispatch();
  const date = new Date();

  const getdataPartner = useSelector((state) => state.dataPartnerReducer);
  const auth = useSelector((state) => state.authReducer);

  const [nomerpartner, setNomerPartner] = React.useState("");
  const [namapartner, setNamaPartner] = React.useState("");
  const [bidangpartner, setBidangPartner] = React.useState("");
  const [emailpartner, setEmailPartner] = React.useState("");
  const [noteleponpartner, setNoTeleponPartner] = React.useState("");
  const [alamatpartner, setAlamatPartner] = React.useState("");
  const [modalAddPartner, setmodalAddPartner] = React.useState(false);
  const [searchText, setSearchText] = React.useState("");
  const [cekemail, setValidasiEmail] = React.useState("");
  const [listPartner, setListPartner] = React.useState([]);

  React.useEffect(() => {
    let tmp =
      getdataPartner.dataPartner &&
      getdataPartner.dataPartner.map((val, index) => {
        // console.log(val);
        return {
          ...val,

          actions: <div className="actions-right"></div>,
        };
      });
    setListPartner(tmp);
  }, [getdataPartner.dataPartner]);

  const customStyles = {
    rows: {
      style: {
        height: 70, // override the row height
      },
    },
    headRow: {
      style: {
        minHeight: 35,
        backgroundColor: "#F8F8F8",
        //fontFamily: "Poppins",
        justifyContent: "center",
      },
    },
    headCells: {
      style: {
        minHeight: 50,
        fontWeight: "bold",
        fontSize: 14,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
    cells: {
      style: {
        minHeight: 50,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
  };

  const colDataPartner = [
    {
      name: "Nomer Partner",
      minWidth: "80px",
      center: true,
      selector: (row) => row.nomerPartner,
    },
    {
      name: "Nama Partner",
      minWidth: "240px",
      center: true,
      selector: (row) => row.namaPartner,
    },
    {
      name: "Bidang Partner",
      minWidth: "200px",
      center: true,
      selector: (row) => row.bidangPartner,
    },
    {
      name: "Email Partner",
      minWidth: "200px",
      center: true,
      selector: (row) => row.emailPartner,
    },
    {
      name: "No Telepon Partner",
      minWidth: "200px",
      center: true,
      selector: (row) => row.noTeleponPartner,
    },
    {
      name: "Alamat Partner",
      minWidth: "200px",
      center: true,
      selector: (row) => row.alamatPartner,
    },
  ];

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const handleCloseModal = () => {
    setNomerPartner("");
    setNamaPartner("");
    setBidangPartner("");
    setEmailPartner("");
    setNoTeleponPartner("");
    setAlamatPartner("");
  };

  function submitpartner(e) {
    if (cekemail === true) {
      tambahPartner({
        nomerPartner: nomerpartner,
        namaPartner: namapartner,
        bidangPartner: bidangpartner,
        emailPartner: emailpartner,
        noTeleponPartner: noteleponpartner,
        alamatPartner: alamatpartner,
      });
    } else {
      Swal.fire({
        title: "Email Tidak Valid",
        icon: "warning",
        showConfirmButton: false,
        timer: 1500,
      });
    }
    e.preventDefault();
  }

  React.useEffect(() => {
    dataPartner(dispatch);
  }, []);

  return (
    <>
      <Container fluid>
        <Card>
          <Col md="12">
            <h2 className="title">Data Partner</h2>
            <Card style={{ border: 0 }}>
              <Row
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Col md={"4"}>
                  {auth.role === "superadmin" ? (
                    <Button
                      className="btn-wd mr-1"
                      onClick={() => {
                        setmodalAddPartner(true);
                      }}
                      variant="primary"
                      style={{ marginBottom: 20, marginTop: 20 }}
                    >
                      Tambah Partner
                    </Button>
                  ) : null}
                </Col>

                <Col md={"3"} style={{ marginTop: 20 }}>
                  <div>
                    <Form.Control
                      style={{
                        marginLeft: 1,
                      }}
                      type="text"
                      value={searchText}
                      onChange={handleSearch}
                      placeholder="Cari Berdasarkan Nama Partner"
                    />
                  </div>
                </Col>
              </Row>
            </Card>

            <Row>
              <Col md="12">
                <DataTable
                  columns={colDataPartner}
                  data={listPartner.filter((row) =>
                    row.namaPartner
                      .toLowerCase()
                      .includes(searchText.toLowerCase())
                  )}
                  customStyles={customStyles}
                  pagination
                />
              </Col>
            </Row>
          </Col>
        </Card>
      </Container>
      <Modal
        size="lg"
        show={modalAddPartner}
        onHide={() => {
          handleCloseModal();
          setmodalAddPartner(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="12">
              <Form action="" className="form" method="">
                <Card>
                  <Card.Header>
                    <Card.Header>
                      <Card.Title as="h4" style={{ fontWeight: "bold" }}>
                        Tambah Partner
                      </Card.Title>
                    </Card.Header>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col sm="12">
                        <Form.Group>
                          <label
                            style={{
                              fontWeight: "bold",
                              fontSize: 14,
                              color: "grey",
                              textTransform: "none",
                            }}
                          >
                            Nomer Partner
                          </label>
                          <Form.Control
                            min={1}
                            value={nomerpartner}
                            onChange={(e) => {
                              setNomerPartner(e.target.value);
                            }}
                            type="number"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col sm="12">
                        <Form.Group>
                          <label
                            style={{
                              fontWeight: "bold",
                              fontSize: 14,
                              color: "grey",
                              textTransform: "none",
                            }}
                          >
                            Nama Partner
                          </label>
                          <Form.Control
                            value={namapartner}
                            onChange={(e) => {
                              setNamaPartner(e.target.value);
                            }}
                            type="text"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col sm="12">
                        <Form.Group>
                          <label
                            style={{
                              fontWeight: "bold",
                              fontSize: 14,
                              color: "grey",
                              textTransform: "none",
                            }}
                          >
                            Bidang Partner
                          </label>
                          <Form.Control
                            value={bidangpartner}
                            onChange={(e) => {
                              setBidangPartner(e.target.value);
                            }}
                            type="text"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col sm="12">
                        <Form.Group>
                          <label
                            style={{
                              fontWeight: "bold",
                              fontSize: 14,
                              color: "grey",
                              textTransform: "none",
                            }}
                          >
                            Email Partner
                          </label>
                          <Form.Control
                            value={emailpartner}
                            onChange={(e) => {
                              setEmailPartner(e.target.value);
                              let cekemail =
                                /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                                  e.target.value
                                );
                              // console.log(cekemail);
                              setValidasiEmail(cekemail);
                            }}
                            type="text"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col sm="12">
                        <Form.Group>
                          <label
                            style={{
                              fontWeight: "bold",
                              fontSize: 14,
                              color: "grey",
                              textTransform: "none",
                            }}
                          >
                            No Telepon Partner
                          </label>
                          <Form.Control
                            value={noteleponpartner}
                            onChange={(e) => {
                              setNoTeleponPartner(e.target.value);
                            }}
                            type="number"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col sm="12">
                        <Form.Group>
                          <label
                            style={{
                              fontWeight: "bold",
                              fontSize: 14,
                              color: "grey",
                              textTransform: "none",
                            }}
                          >
                            Alamat Partner
                          </label>
                          <Form.Control
                            value={alamatpartner}
                            onChange={(e) => {
                              setAlamatPartner(e.target.value);
                            }}
                            type="text"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Button
                      className="btn-fill pull-right"
                      type="submit"
                      variant="info"
                      onClick={submitpartner}
                    >
                      Submit
                    </Button>
                    <div className="clearfix"></div>
                  </Card.Body>
                </Card>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default TambahPartner;
