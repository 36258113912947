import React from "react";

// react-bootstrap components
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Modal,
  Form,
  Table,
  Image,
  FormGroup,
} from "react-bootstrap";
import Select from "react-select";
// core components
import ReactTable from "components/ReactTable/ReactTable.js";
import DataTable from "react-data-table-component";
import { addProject, dataProject } from "../../stores";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactDatetime from "react-datetime";
import moment from "moment";

function TambahProject() {
  const dispatch = useDispatch();
  const date = new Date();
  const project = useSelector((state) => state.dataProjectReducer);

  const auth = useSelector((state) => state.authReducer);
  // const [modal, setModal] = React.useState(false);
  const [nomerproject, setNomerProject] = React.useState("");
  const [namaproject, setNamaProject] = React.useState("");
  const [kodeproject, setKodeProject] = React.useState("");
  const [datelineproject, setDatelineProject] = React.useState("");
  const [modalAddProject, setmodalAddProject] = React.useState(false);
  const [listProject, setListProject] = React.useState([]);
  const [dariTgl, setDariTgl] = React.useState(
    new Date(date.getFullYear(), date.getMonth(), date.getDate()).setHours(
      7,
      0,
      0,
      0
    )
  );

  React.useEffect(() => {
    let tmp =
      project.dataProject &&
      project.dataProject.map((val, index) => {
        // console.log(val);
        return {
          ...val,

          actions: (
            <div className="actions-right">
              {/* <Button
                onClick={() => {
                  setModalEditMenuBaru(true);
                  setIdMenuBaru(val._id);
                  setKodeMenu(val.kodeMenu);
                  setMenuBaru(val.namaMenu);
                }}
                variant="info"
                size="md"
                className="btn-link remove text-info"
              >
                <i className="fa fa-edit fa-lg" />
              </Button>{" "} */}
              {/* <Button
                onClick={() => {
                  // deletedIdMenuBaru(val.kodeMenu);
                  deleteMenuKantinBaru({
                    kodeMenuBaru: val.kodeMenu,
                  });
                }}
                variant="danger"
                size="md"
                className="btn-link remove text-danger"
              >
                <i className="fas fa-times fa-lg" />
              </Button>{" "} */}
            </div>
          ),
        };
      });
    setListProject(tmp);
  }, [project.dataProject]);

  const customStyles = {
    rows: {
      style: {
        height: 70, // override the row height
      },
    },
    headRow: {
      style: {
        minHeight: 35,
        backgroundColor: "#F8F8F8",
        //fontFamily: "Poppins",
        justifyContent: "center",
      },
    },
    headCells: {
      style: {
        minHeight: 50,
        fontWeight: "bold",
        fontSize: 14,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
    cells: {
      style: {
        minHeight: 50,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
  };

  const colDataProject = [
    {
      name: "Nomer Project",
      width: "140px",
      center: true,
      selector: (row) => row.nomerProject,
    },
    {
      name: "Nama Project",
      minWidth: "160px",
      center: true,
      selector: (row) => row.namaProject,
    },
    {
      name: "Kode Project",
      minWidth: "160px",
      center: true,
      selector: (row) => row.kodeProject,
    },
    {
      name: "Dateline Project",
      minWidth: "160px",
      center: true,
      selector: (row) => moment(row.datelineProject).format("DD MMMM YYYY"),
    },
  ];

  const handleCloseModal = () => {
    setNomerProject("");
    setNamaProject("");
    setKodeProject("");
  };

  function submitproject(e) {
    e.preventDefault();
    addProject({
      nomerProject: nomerproject,
      namaProject: namaproject,
      kodeProject: kodeproject,
      datelineProject: datelineproject,
    });
  }

  React.useEffect(() => {
    dataProject(dispatch);
  }, []);

  return (
    <>
      <Container fluid>
        {/* <Button
          className="btn-wd mr-1"
          onClick={() => {
            setModal(true);
          }}
          variant="primary"
        >
          Pengajuan Project
        </Button> */}

        <Card>
          <Col md="12">
            <h2 className="title">Data Project</h2>

            <Col md="12">
              {auth.role === "superadmin" ? (
                <Button
                  className="btn-wd mr-1"
                  onClick={() => {
                    setmodalAddProject(true);
                  }}
                  variant="primary"
                  style={{ marginBottom: 20, marginTop: 20 }}
                >
                  Tambah Project
                </Button>
              ) : null}
              <Row>
                <Col md="12">
                  <DataTable
                    columns={colDataProject}
                    data={listProject}
                    customStyles={customStyles}
                    pagination
                  />
                </Col>
              </Row>
            </Col>
          </Col>
        </Card>
      </Container>
      <Modal
        size="lg"
        show={modalAddProject}
        onHide={() => {
          handleCloseModal();
          setmodalAddProject(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="12">
              <Form action="" className="form" method="">
                <Card>
                  <Card.Header>
                    <Card.Header>
                      <Card.Title as="h4" style={{ fontWeight: "bold" }}>
                        Tambah Project
                      </Card.Title>
                    </Card.Header>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col sm="12">
                        <Form.Group>
                          <label
                            style={{
                              fontWeight: "bold",
                              fontSize: 14,
                              color: "grey",
                              textTransform: "none",
                            }}
                          >
                            Nomer Project
                          </label>
                          <Form.Control
                            min={1}
                            onChange={(e) => {
                              setNomerProject(e.target.value);
                            }}
                            type="number"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col sm="12">
                        <Form.Group>
                          <label
                            style={{
                              fontWeight: "bold",
                              fontSize: 14,
                              color: "grey",
                              textTransform: "none",
                            }}
                          >
                            Nama Project
                          </label>
                          <Form.Control
                            onChange={(e) => {
                              setNamaProject(e.target.value);
                            }}
                            type="text"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col sm="12">
                        <Form.Group>
                          <label
                            style={{
                              fontWeight: "bold",
                              fontSize: 14,
                              color: "grey",
                              textTransform: "none",
                            }}
                          >
                            Kode Project
                          </label>
                          <Form.Control
                            onChange={(e) => {
                              setKodeProject(e.target.value);
                            }}
                            type="text"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col sm="12">
                        <Form.Group>
                          <label
                            style={{
                              fontWeight: "bold",
                              fontSize: 14,
                              color: "grey",
                              textTransform: "none",
                            }}
                          >
                            Dateline Project
                          </label>
                          <Form.Control
                            onChange={(e) => {
                              setDatelineProject(e.target.value);
                            }}
                            type="date"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Button
                      className="btn-fill pull-right"
                      type="submit"
                      variant="info"
                      onClick={submitproject}
                    >
                      Submit
                    </Button>
                    <div className="clearfix"></div>
                  </Card.Body>
                </Card>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default TambahProject;
