const initialState = {
  dataUsers: [],
  getDataUsers: [],
};

const dataUsersReducer = (state = initialState, action) => {
  let { type, data } = action;

  switch (type) {
    case "SET_GETALL_DATAUSERS":
      return {
        ...state,
        dataUsers: data,
      };
    default:
      return state;
  }
};

export default dataUsersReducer;