import React from "react";

// react-bootstrap components
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Modal,
  Form,
  Table,
  Image,
  FormGroup,
} from "react-bootstrap";
import Select from "react-select";
// core components
import ReactTable from "components/ReactTable/ReactTable.js";
import DataTable from "react-data-table-component";
import { addUser, dataUsers } from "../../stores";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactDatetime from "react-datetime";
import moment from "moment";

function TambahUser() {
  const dispatch = useDispatch();
  const date = new Date();
  const user = useSelector((state) => state.dataUsersReducer);
  const auth = useSelector((state) => state.authReducer);
  // const [modal, setModal] = React.useState(false);
  const [nama, setNama] = React.useState("");
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [role, setRole] = React.useState("");
  const [modalAddUser, setModalAddUser] = React.useState(false);
  const [listUser, setListUser] = React.useState([]);

  React.useEffect(() => {
    let tmp =
      user.dataUsers &&
      user.dataUsers.map((val, index) => {
        // console.log(val);
        return {
          ...val,

          actions: (
            <div className="actions-right">
              {/* <Button
                onClick={() => {
                  setModalEditMenuBaru(true);
                  setIdMenuBaru(val._id);
                  setKodeMenu(val.kodeMenu);
                  setMenuBaru(val.namaMenu);
                }}
                variant="info"
                size="md"
                className="btn-link remove text-info"
              >
                <i className="fa fa-edit fa-lg" />
              </Button>{" "} */}
              {/* <Button
                onClick={() => {
                  // deletedIdMenuBaru(val.kodeMenu);
                  deleteMenuKantinBaru({
                    kodeMenuBaru: val.kodeMenu,
                  });
                }}
                variant="danger"
                size="md"
                className="btn-link remove text-danger"
              >
                <i className="fas fa-times fa-lg" />
              </Button>{" "} */}
            </div>
          ),
        };
      });
    setListUser(tmp);
  }, [user.dataUsers]);

  const customStyles = {
    rows: {
      style: {
        height: 70, // override the row height
      },
    },
    headRow: {
      style: {
        minHeight: 35,
        backgroundColor: "#F8F8F8",
        //fontFamily: "Poppins",
        justifyContent: "center",
      },
    },
    headCells: {
      style: {
        minHeight: 50,
        fontWeight: "bold",
        fontSize: 14,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
    cells: {
      style: {
        minHeight: 50,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
  };

  const colDataUser = [
    {
      name: "No",
      width: "80px",
      center: true,
      selector: (row) => listUser.indexOf(row) + 1,
    },
    {
      name: "Nama",
      minWidth: "160px",
      center: true,
      selector: (row) => row.nama,
    },
    {
      name: "Username",
      minWidth: "160px",
      center: true,
      selector: (row) => row.username,
    },
    {
      name: "Role",
      minWidth: "160px",
      center: true,
      selector: (row) => row.role,
      cell: (row) => {
        const statusStyle = getRoleStyle(row.role);
        return (
          <div
            style={{
              fontWeight: statusStyle.fontWeight,
              backgroundColor: statusStyle.backgroundColor,
              color: statusStyle.textColor,
              padding: "10px",
              borderRadius: "5px",
            }}
          >
            {row.role}
          </div>
        );
      },
    },
  ];

  const handleCloseModal = () => {
    setNama("");
    setUsername("");
    setPassword("");
    setRole("");
  };

  const handleRoleChange = (selectedOption) => {
    if (selectedOption) {
      // Jika ada opsi yang dipilih, ambil nilai 'value' dari objek yang dipilih
      setRole(selectedOption.value);
    } else {
      // Jika tidak ada opsi yang dipilih (misalnya, pilih "Pilih Role"), atur nilai 'role' menjadi kosong ('')
      setRole("");
    }
  };

  const getRoleStyle = (role) => {
    switch (role) {
      case "superadmin":
        return {
          fontWeight: "bold",
          backgroundColor: "#ABBFDF",
          textColor: "#002C7C",
        };
      case "admin":
        return {
          fontWeight: "bold",
          backgroundColor: "#FFD6AB",
          textColor: "#FF7C00",
        };
      default:
        return {};
    }
  };

  function submituser(e) {
    e.preventDefault();
    addUser({
      nama: nama,
      username: username,
      password: password,
      role: role,
    });
  }

  React.useEffect(() => {
    dataUsers(dispatch);
  }, []);

  return (
    <>
      <Container fluid>
        {}

        <Card>
          <Col md="12">
            <h2 className="title">Data User</h2>

            <Col md="12">
              {auth.role === "superadmin" ? (
                <Button
                  className="btn-wd mr-1"
                  onClick={() => {
                    setModalAddUser(true);
                  }}
                  variant="primary"
                  style={{ marginBottom: 20, marginTop: 20 }}
                >
                  Tambah User
                </Button>
              ) : null}
              <Row>
                <Col md="12">
                  <DataTable
                    columns={colDataUser}
                    data={listUser}
                    customStyles={customStyles}
                    pagination
                  />
                </Col>
              </Row>
            </Col>
          </Col>
        </Card>
      </Container>
      <Modal
        size="lg"
        show={modalAddUser}
        onHide={() => {
          handleCloseModal();
          setModalAddUser(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="12">
              <Form action="" className="form" method="">
                <Card>
                  <Card.Header>
                    <Card.Header>
                      <Card.Title as="h4" style={{ fontWeight: "bold" }}>
                        Tambah User
                      </Card.Title>
                    </Card.Header>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col sm="12">
                        <Form.Group>
                          <label
                            style={{
                              fontWeight: "bold",
                              fontSize: 14,
                              color: "grey",
                              textTransform: "none",
                            }}
                          >
                            Nama
                          </label>
                          <Form.Control
                            onChange={(e) => {
                              setNama(e.target.value);
                            }}
                            type="text"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col sm="12">
                        <Form.Group>
                          <label
                            style={{
                              fontWeight: "bold",
                              fontSize: 14,
                              color: "grey",
                              textTransform: "none",
                            }}
                          >
                            Username
                          </label>
                          <Form.Control
                            onChange={(e) => {
                              setUsername(e.target.value);
                            }}
                            type="text"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col sm="12">
                        <Form.Group>
                          <label
                            style={{
                              fontWeight: "bold",
                              fontSize: 14,
                              color: "grey",
                              textTransform: "none",
                            }}
                          >
                            Password
                          </label>
                          <Form.Control
                            onChange={(e) => {
                              setPassword(e.target.value);
                            }}
                            type="password"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col sm="12">
                        <Form.Group>
                          <label
                            style={{
                              fontWeight: "bold",
                              fontSize: 14,
                              color: "grey",
                              textTransform: "none",
                            }}
                          >
                            Role
                          </label>
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="singleRole"
                            onChange={handleRoleChange}
                            options={[
                              {
                                value: "",
                                label: "Pilih Role",
                                isDisabled: true,
                              },
                              { value: "admin", label: "Admin" },
                            ]}
                            placeholder="Pilih Role"
                            value={
                              role
                                ? {
                                    value: role,
                                    label:
                                      role.charAt(0).toUpperCase() +
                                      role.slice(1), // Tampilan huruf kapital untuk label yang dipilih
                                  }
                                : null
                            }
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Button
                      className="btn-fill pull-right"
                      type="submit"
                      variant="info"
                      onClick={submituser}
                    >
                      Submit
                    </Button>
                    <div className="clearfix"></div>
                  </Card.Body>
                </Card>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default TambahUser;
