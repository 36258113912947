import axios from "axios";
import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { reduxSoldierMiddleware } from "redux-soldier";

// reducer
import authReducer from "./auth";
import dashboardReducer from "./dashboardutama";
import purchaseOrderReducer from "./purchaseorder";
import dataProjectReducer from "./project";
import dataUsersReducer from "./user";
import dataPartnerReducer from "./partner";
import dashboardUtamaReducer from "./dashboardutama";

// function
export * from "./auth/function";
export * from "./purchaseorder/function";
export * from "./project/function";
export * from "./user/function";
export * from "./partner/function";
export * from "./errorHandler";
export * from "./dashboardutama/function";

// server api
// export const baseUrl = process.env.REACT_APP_URL
export const baseUrl = "https://apimonitoringscs.ptbap.net/";
// export const baseUrl = "http://192.168.100.157:9030/";
// export const baseUrl = "http://localhost:9030/";

export const baseAxios = axios.create({
  baseURL: baseUrl,
  timeout: 10000,
});

const rootReducer = combineReducers({
  authReducer,
  dashboardReducer,
  purchaseOrderReducer,
  dataUsersReducer,
  dataProjectReducer,
  dataPartnerReducer,
  dashboardUtamaReducer,
});

export const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(reduxSoldierMiddleware))
);
