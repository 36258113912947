import { baseAxios, errorHandler } from "../index";
import Swal from "sweetalert2";

export async function totalPurchaseOrderDashboard(dispatch) {
  try {
    const response = await baseAxios.get(
      "webDashboard/totalPurchaseOrderDashboard",
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    dispatch({
      type: "SET_GETALL_TOTALPURCHASEORDERDASHBOARD",
      data: response.data,
    });
    // console.log(response, "TOTAL PURCHASE ORDER");
    return response;
  } catch (err) {
    return err.response;
  }
}

export function dataPurchaseOrderDashboard(dispatch) {
  baseAxios
    .get("/webDashboard/dataPurchaseOrderDashboard", {
      headers: { token: localStorage.getItem("token") },
    })

    .then((respon) => {
      // console.log(respon, "DATA PO");
      dispatch({
        type: "SET_GETALL_DATAPURCHASEORDERDASHBOARD",
        data: respon.data,
      });
    })
    .catch(errorHandler);
}

export async function totalPartnerDashboard(dispatch) {
  try {
    const response = await baseAxios.get("webDashboard/totalPartnerDashboard", {
      headers: { token: localStorage.getItem("token") },
    });
    dispatch({ type: "SET_GETALL_TOTALPARTNERDASHBOARD", data: response.data });
    // console.log(response, "TOTAL PARTNER");
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function totalNominalDashboard(dispatch) {
  try {
    const response = await baseAxios.get("webDashboard/totalNominalDashboard", {
      headers: { token: localStorage.getItem("token") },
    });
    dispatch({ type: "SET_GETALL_TOTALNOMINALDASHBOARD", data: response.data });
    // console.log(response, "TOTAL NOMINAL");
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function totalProjectDashboard(dispatch) {
  try {
    const response = await baseAxios.get("webDashboard/totalProjectDashboard", {
      headers: { token: localStorage.getItem("token") },
    });
    dispatch({ type: "SET_GETALL_TOTALPROJECTDASHBOARD", data: response.data });
    // console.log(response, "TOTAL PROJECT");
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function totalStatusDashboard(dispatch) {
  try {
    const response = await baseAxios.get("webDashboard/totalStatusDashboard", {
      headers: { token: localStorage.getItem("token") },
    });
    dispatch({ type: "SET_GETALL_TOTALSTATUSDASHBOARD", data: response.data });
    // console.log(response, "TOTAL STATUS");
    return response;
  } catch (err) {
    return err.response;
  }
}
